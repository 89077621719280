// load the possible configurations from the global .env file
const apiProdConfig = {
    endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
    version: process.env.REACT_APP_PROD_API_VERSION,
    recipeApiEndpoint: process.env.REACT_APP_DELIZIOSO_PROD_API_ENDPOINT,
    recipeApiVersion: process.env.REACT_APP_DELIZIOSO_PROD_API_VERSION,
    recipeApiKey: process.env.REACT_APP_DELIZIOSO_PROD_API_KEY
}

const apiDevConfig = {
    endpoint: process.env.REACT_APP_DEV_API_ENDPOINT,
    version: process.env.REACT_APP_DEV_API_VERSION,
    recipeApiEndpoint: process.env.REACT_APP_DELIZIOSO_DEV_API_ENDPOINT,
    recipeApiVersion: process.env.REACT_APP_DELIZIOSO_DEV_API_VERSION,
    recipeApiKey: process.env.REACT_APP_DELIZIOSO_DEV_API_KEY
}

// use the config that fits the current environment
const apiConfig = process.env.NODE_ENV === 'production'
  ? apiProdConfig
  : apiDevConfig

export default apiConfig