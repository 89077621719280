import * as recipesApi from '../../api/recipes'

// Tool for the next API method recipe/search
// This function chooses a random recipe inside the list of recipes returned by Firebase
const randomRecipe = (recipes) => {
    let randomIndex = recipes.length * Math.random() << 0;

    return recipes[randomIndex];
}

const extractIngredientsFilter = (userDislikes) => {
    if( typeof userDislikes.ingredients !== 'undefined' && userDislikes.ingredients.length !== 0 ) {
        return userDislikes.ingredients.map((ingredient) => ingredient.id )
    } else {
        return [];
    }
}

const extractTagsFilter = (userDislikes) => {
    if( typeof userDislikes.tags !== 'undefined' && userDislikes.tags.length !== 0 ) {
        return userDislikes.tags.map((tag) => tag.id )
    } else {
        return [];
    }
}

const matcher = async ({ 
    type = "main", 
    exclusion = [], 
    dislikes = {} 
}) => {
    let ingredientsToAvoid = extractIngredientsFilter(dislikes);
    let tagsToAvoid = extractTagsFilter(dislikes);

    let recipes = await recipesApi.postRecipeSearch({ 
        type: type, 
        exclusion: exclusion, 
        excludeIngredients: ingredientsToAvoid,
        excludeTags: tagsToAvoid,
    })
    
    if( typeof recipes === 'undefined' || recipes.length === 0 ) {
        return {}
    } else {
        return randomRecipe(recipes);
    }
}

export default matcher