import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { Row, Col, Button } from 'antd'

const condition = (auth) => !!auth

const colResponsive = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
}

const ShopHomeContent = (props) =>
    <div id="shophome-content">
        <Row>
            <Col>
                <h2>Aucun panier existant</h2>
                <p>Vous pouvez organiser vos courses sur la base des plannings que vous avez définis.</p>
            </Col>
        </Row>
        <Row 
            type="flex"
            gutter={8}
            justify="center"
            align="top"
        >
            <Col {...colResponsive}>
                <div>Aucun panier défini pour l'instant</div>
                <Button onClick={props.onLoading}>Lancement du chargement</Button>
            </Col>
        </Row>
    </div>

const ShopHome = templatePrivatePage(ShopHomeContent, "Courses")

export default withAuthorization(condition)(ShopHome)