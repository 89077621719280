import React from 'react'
import { ReactComponent as PlanIcon } from './plan.svg'
import { ReactComponent as ShopIcon } from './shop.svg'
import { ReactComponent as CookIcon } from './book.svg'
import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as CardIcon } from './card.svg'
import { ReactComponent as ChatIcon } from './chat.svg'
import { ReactComponent as SettingsIcon } from './settings.svg'



const ToolbarButton = (Component) => (props) => {
    // an additional active or inactive classname can be passed to component ; we append it to default class
    let classNames = ["toolbar-action", props.className].join(" ");

    return(
        <div {...props} className={classNames} onClick={props.onClick}>
            <Component />
        </div>
    )
}

const CardButton = ToolbarButton(CardIcon)
const PlanButton = ToolbarButton(PlanIcon)
const ShopButton = ToolbarButton(ShopIcon)
const CookButton = ToolbarButton(CookIcon)
const HomeButton = ToolbarButton(HomeIcon)
const UserButton = ToolbarButton(UserIcon)
const MenusButton = ToolbarButton(CookIcon)
const ChatButton = ToolbarButton(ChatIcon)
const SettingsButton = ToolbarButton(SettingsIcon)

export {
    PlanButton,
    ShopButton,
    CookButton,
    HomeButton,
    UserButton,
    CardButton,
    MenusButton,
    ChatButton,
    SettingsButton
}