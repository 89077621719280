import React from 'react'

import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import * as roles from '../../../constants/roles'
import { message } from 'antd'
import * as users from '../../../api/users'
import { PageHeader } from '../../Content/Layout/Headers'
import UsersList from './UsersList'

const condition = auth => !!auth && typeof auth.roles !== 'undefined' && auth.roles[roles.ADMIN]

class AdminContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        }
    }

    async componentDidMount() {
        this.props.onLoading();

        try{
            let usersList = await users.getUsers();

            this.setState({ users: usersList, });

        } catch(err) {
            message.error("Une erreur s'est produite lors de la récupération des utilisateurs.");
        }

        this.props.onLoaded();
    }

    render() {
        return(
            <div id="admin-home">
                <PageHeader label="Page d'admin" />
                <UsersList users={this.state.users} />
            </div>
        );
    }
}

const Admin = templatePrivatePage(AdminContent, "Administration")

export default withAuthorization(condition)(Admin)