import React from 'react'
import { Row } from 'antd'
import moment from 'moment'

const DinnerCounter = (props) => 
    props.nbOfDinners !== 0
    ?
        <div className="metric dinners">
            <span className="value">{props.nbOfDinners}</span>
            <span className="label">{props.nbOfDinners === 0 || props.nbOfDinners === 1 ? "dîner" : "dîners"}</span>
        </div>
    :
        <div className="metric dinners">
            <span className="value">{props.nbOfDinners}</span>
            <span className="label">{props.nbOfDinners === 0 || props.nbOfDinners === 1 ? "dîner" : "dîners"}</span>
        </div>


const LunchCounter = (props) => 
    props.nbOfLunches !== 0
    ?
        <div className="metric lunches">
            <span className="value">{props.nbOfLunches}</span>
            <span className="label">{props.nbOfLunches === 0 || props.nbOfLunches === 1 ? "déjeuner" : "déjeuners"}</span>
        </div>
    :
        <div className="metric lunches">
            <span className="value">{props.nbOfLunches}</span>
            <span className="label">{props.nbOfLunches === 0 || props.nbOfLunches === 1 ? "déjeuner" : "déjeuners"}</span>
        </div>

const FirstDay = (props) => 
    <div className="metric firstday">
        <span className="value">{props.date}</span>
        <span className="label">premier jour</span>
    </div>

const MealCounter = (props) => {
    let {
        id,
        lunches,
        dinners,
        startDate
    } = props.planning;

    let nbOfLunches = typeof lunches === 'undefined' ? 0 : lunches.length;
    let nbOfDinners = typeof dinners === 'undefined' ? 0 : dinners.length;

    let canDisplay = nbOfDinners !== 0 || nbOfLunches !== 0;

    return(
        canDisplay &&
        
        <Row className="meal-counter">
            <div className="metrics">
                <LunchCounter nbOfLunches={nbOfLunches} id={id} />
                <DinnerCounter nbOfDinners={nbOfDinners} id={id} />
                <FirstDay date={typeof startDate === 'undefined' ? '-' : moment(startDate).format("DD/MM/YY")} />
            </div>
        </Row>
    )
}

export default MealCounter
