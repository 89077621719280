import React from 'react'
import { Layout, Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import * as users from '../../../../api/users'
import * as routes from '../../../../constants/routes'

const { Content } = Layout

const templateOnboardingPage = (PageContent, PageFooter) => {
    class templateOnboardingPageComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isPageLoading: false,
            }
        
            this.pageLoading = this.pageLoading.bind(this);
            this.pageLoaded = this.pageLoaded.bind(this);
        }

        componentDidMount() {
            // initialize the drift button. Only solution I imagined to solve the issue
            // of having the drift button working on every page of the app
            if( typeof window !== 'undefined' && window.drift ) {
                window.drift.on('ready', (api, payload) => {
                    api.widget.hide();

                    function forEachElement(selector, fn) {
                        var elements = document.querySelectorAll(selector);
                        for (var i = 0; i < elements.length; i++)
                            fn(elements[i], i);
                    }

                    let DRIFT_CHAT_SELECTOR = '.drift-open-chat';

                    function openSidebar(driftApi, event) {
                        event.preventDefault();
                        driftApi.sidebar.open();
                        return false;
                    }

                    let handleClick = openSidebar.bind(this, api);

                    forEachElement(DRIFT_CHAT_SELECTOR, function(el) {
                        el.addEventListener('click', handleClick);
                    });
                });
            }
        }

        pageLoading = () => this.setState({ isPageLoading: true, })
        pageLoaded = () => this.setState({ isPageLoading: false, })

        handleLastStep = async () => {
            let { uid } = this.props.user;
            
            this.pageLoading();

            try{
                // update the user to indicate that he completed the onboarding
                // so that s.he's not displayed the onboarding again in the future
                await users.doUserCompleteOnboarding(uid);
                this.pageLoaded();
                this.props.history.push(routes.HOME);
            } catch(err) {
                // even in case of error when updating the user, we push user to home page
                this.pageLoaded();
                this.props.history.push(routes.HOME)
            }
        }

        trackPage = (user, page = null) => {
            if( typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
                
                let userTraits = {
                    name: user.firstName + ' ' + user.name,
                    email: user.email
                }

                window.analytics.identify(user.uid, userTraits);
                
                if( !!page ) {
                    if( typeof page.properties !== 'undefined') {
                        window.analytics.page(page.name, page.properties);
                    } else {
                        window.analytics.page(page.name);
                    }
                    
                }
            }
        }

        render() {
            return(
                <Spin spinning={this.state.isPageLoading}>
                    <Layout className="app-main onboarding-page">
                        <Content className="page-content">
                            <PageContent {...this.props} trackPage={this.trackPage} />
                            <PageFooter {...this.props} onLastStep={this.handleLastStep} />
                        </Content>
                    </Layout>
                </Spin>
            );
        }
    }

    return withRouter(templateOnboardingPageComponent);
}

export default templateOnboardingPage