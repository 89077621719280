import React from 'react'

const StepsList = (props) =>
    <ol className="steps-list">
        {props.steps.map((step, index) => 
            <li key={step.order}>
                {step.description}
            </li>
        )}
    </ol>

export default StepsList