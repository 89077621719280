import React from 'react'
import MealTile from './MealTile'
import { Row, Col, Icon } from 'antd'
import LinkButton from '../Buttons/LinkButton'
import * as routes from '../../../constants/routes'

/* standard component that will handle the creation of card for empty meals */
const EmptyMealsList = ({planningId, mealType, ...props}) => {
    const isLunch = mealType === "lunch";

    const title = 
        isLunch
        ? "Pas de restaurant ce midi !"
        : "Qu'est-ce qu'on mange ce soir ?";

    const pluralMealType = 
        isLunch
        ? "déjeuners"
        : "dîners";

    const actionLink = isLunch ? routes.PLANNING_LUNCHES_CHOICE(planningId) : routes.PLANNING_DINNERS_CHOICE(planningId);
    const action = <LinkButton className="add-meals" to={actionLink} type="link"><Icon type="plus-circle" theme="filled" /> Ajouter des {pluralMealType}</LinkButton>;
    const paragraph = 
        isLunch
        ?   `Pour tes déjeuners, tu peux sélectionner des recettes ou ajouter des portions de tes dîners. 
            Tu cuisines un peu plus le soir pour de meilleurs déjeuners !`
        :   `Dîners rapides pour les soirées  de semaine ou recettes gourmandes lorsqu’on reçoit. 
            Nous avons tout ce qu’il te faut pour manger sain et simple.`;
    
    return(
        <Col>
            <div className="meal-card--empty">
                {props.showTitle && <h3>{title}</h3>}
                <p>{paragraph}</p>
                {action}
            </div>
        </Col>
    )
}

/* empty card for lunches and dinners */
const EmptyLunchesList = (props) => 
    <EmptyMealsList 
        planningId={props.planningId}
        mealType="lunch"
        {...props}
    />

const EmptyDinnersList = (props) => 
    <EmptyMealsList 
        planningId={props.planningId}
        mealType="dinner"
        {...props}
    />


/* the MealsList component is a standard component that will display meals (either lunches or */
/* dinners) as a list of recipes / menus in a slider */
const MealsCarousel = (mealType) => ({planning, meals, ...props}) => {
    const isLunch = mealType === "lunch";
    const isEmpty = meals.length === 0;
    const cardTitle = 
        isLunch 
        ? <span><span role="img" aria-label="bento">🍱</span>  Tes déjeuners</span> 
        : <span><span role="img" aria-label="night_with_stars">🌃</span>  Tes dîners</span>;

    if( isEmpty ) {
        return(
            <div id={mealType} className="meals-section">
                <Row>
                    <Col>
                        <h2>{cardTitle}</h2>
                    </Col>
                </Row>
                <Row>
                    {isLunch && <EmptyLunchesList planningId={planning.id} />}
                    {!isLunch && <EmptyDinnersList planningId={planning.id} />}
                </Row>
            </div>
        );
    } else {
        const divClassName = isLunch ? "lunches-list" : "dinners-list";

        return(
            <div id={mealType} className="meals-section">
                <Row>
                    <Col>
                        <h2>{cardTitle}</h2>
                    </Col>
                </Row>
                <Row className={"meals-list " + divClassName}>
                    <Col>
                        {meals.map(meal => 
                        <MealTile key={meal.id} meal={meal} planning={planning} onMealDeleted={props.onMealDeleted(mealType)}  loadMeal={props.loadMeal} />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const LunchesCarousel = MealsCarousel("lunch")

const DinnersCarousel = MealsCarousel("dinner")

const Meals = (props) => {
    let { lunches, dinners } = props.planning;

    if( lunches.length === 0 ) {
        return(
            <div className="meals">
                <DinnersCarousel planning={props.planning} meals={dinners} onMealDeleted={props.onMealDeleted}  loadMeal={props.loadMeal} />
                <LunchesCarousel planning={props.planning} meals={lunches} onMealDeleted={props.onMealDeleted}  loadMeal={props.loadMeal} />
            </div>
        );
    } else {
        return(
            <div className="meals">
                <LunchesCarousel planning={props.planning} meals={lunches} onMealDeleted={props.onMealDeleted}  loadMeal={props.loadMeal} />
                <DinnersCarousel planning={props.planning} meals={dinners} onMealDeleted={props.onMealDeleted}  loadMeal={props.loadMeal} />
            </div>
        );
    }
}

export { 
    EmptyDinnersList,
    EmptyLunchesList,
    DinnersCarousel,
    LunchesCarousel,
    Meals
}