import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

export const LinkButton = ({to, type, className, ...props}) => {
    return (
        <div className={className}>
            <Link to={to}>
                <Button type={type} {...props}>
                    {props.children}
                </Button>
            </Link>
        </div>
    );
}

export default LinkButton