import React from 'react'
import templateOnboardingPage from '../../Templates/OnboardingPage'
import { withAuthorization } from '../../../../services/sessions'
import { PageHeader } from '../../../Content/Layout/Headers'
import { Row, Col, Icon, Button } from 'antd'
import { Step3Cover } from '../../../Content/Layout/PageCover'
import { LinkButton } from '../../../Content/Buttons/LinkButton'
import { Toolbar } from '../../../Content/Layout/Toolbar'
import * as routes from '../../../../constants/routes'

const condition = (auth) => !!auth

class PageContent extends React.Component {
    componentDidMount() {
        let page = { name: 'Onboarding / Step 3' };
        this.props.trackPage(this.props.user, page);
    }

    render() {
        return(
            <div className="withToolbar">
                <Step3Cover />
                <PageHeader label="Toujours en contact" />
                <Row className="content">
                    <Col>
                        <p>
                            Tu fais partie des premiers utilisateurs de cette application (merci <span role="img" aria-label="pray">🙏</span>). 
                            Il est donc possible que tu aies des questions, que tu aies besoin de conseils. 
                        </p>
                        <p>
                            Pas de souci, notre équipe est là pour toi !
                        </p>
                        <p>
                            Clique sur le bouton de conversation en bas à droite de l'application. Ecris-nous et nous apporterons une réponse à 
                            tes questions.
                        </p>
                        <p>
                            Alors es-tu prêt.e à commencer ?
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }
}

const PageFooter = (props) => 
    <Toolbar className="funnel-toolbar">
        <Col span={12}>
            <div className="toolbar-action left-action">
                <LinkButton to={routes.ONBOARDING_STEP2} type="default"><Icon type="step-backward" />  Retour</LinkButton>
            </div>
        </Col>
        <Col span={12}>
            <div className="toolbar-action right-action">
                <Button onClick={props.onLastStep} type="primary">C'est parti !&nbsp;&nbsp;<span role="img" aria-label="rocket">🚀</span></Button>
            </div>
        </Col>
    </Toolbar>


const OnboardingStep3 = templateOnboardingPage(PageContent, PageFooter)

export default withAuthorization(condition)(OnboardingStep3)