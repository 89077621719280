import React from 'react'
import { auth } from '../firebase'
import AuthUserContext from './context'
import * as users from '../../api/users'

const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            // updater function that should be triggered when client side modifications are made to
            // the user AND are aknowledged by the server. Then the user will be globally updated
            // for the whole app
            this.update = async (callbackFn) => {
                let updatedUser = await users.getUserDetail(this.state.authUser.uid);
                this.setState(state => ({
                    authUser: { ...state.authUser, ...updatedUser }
                }), callbackFn());
            }

            // state also contains the updater function
            this.state = {
                authUser: null,
                update: this.update
            }
        }
        
        componentDidMount() {
            this.listener = auth.onAuthStateChanged(
                async authUser => {
                    if( authUser ) {
                        let userDb = await users.getUserDetail(authUser.uid);
                        
                        if( typeof userDb === 'undefined') {
                            throw new Error("Authentication issue");
                        }
                        // Default : empty roles
                        if (typeof userDb.roles === 'undefined' || !userDb.roles) {
                            userDb.roles = {};
                        }
                        
                        // merge auth and user db
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            ...userDb,
                        };

                        this.setState({ 
                            authUser: authUser,
                        }, () => {
                            // feed third party tools with the user's data
                            if( typeof window !== 'undefined' ) {
                                // drift user identification
                                // console.log('Drift identification');
                                if( window.drift ) {
                                    let attributes = { 
                                        email: authUser.email,
                                        first_name: authUser.firstName,
                                        last_name: authUser.name, 
                                    };

                                    window.drift.identify(authUser.uid, attributes);
                                }

                                // segment user identification
                                if( window.analytics ) {
                                    // console.log('Segment identification');
                                    window.analytics.identify(authUser.uid, {
                                        name: authUser.firstName+' '+authUser.name,
                                        email: authUser.email
                                    })
                                }
                            }
                        });
                    } else {
                        // console.log("Sign out ?");
                        // when user is unauthenticated we reset the drift chat
                        // this prevents another user who would authenticate on same browser / smartphone
                        // to access previous user's conversations
                        if( typeof window !== 'undefined' && window.drift ) {
                            console.log("drift reset");
                            window.drift.reset();
                        }
                        // we also reset the segment identification
                        if( typeof window !== 'undefined' && window.analytics ) {
                            console.log("segment reset");
                            window.analytics.reset();
                        }

                        // TODO : move the code right above in a callback function of the following setState ?

                        this.setState({ authUser: null, });
                    }
                }
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return WithAuthentication;
}

export default withAuthentication