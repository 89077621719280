import apiConfig from './apiConfig'

const apiURI = apiConfig.recipeApiEndpoint + apiConfig.recipeApiVersion
const recipesURI = apiURI + '/recipes'
const apiKey = apiConfig.recipeApiKey

const cleanRecipeFromApi = (data) => {
    // clean the data to ease the process of displaying them in the app
    // turn the ingredients list (an object) into an array
    // We keep the id of each ingredient in order to be able to request them to the api
    let ingredients = [];

    for( var ingredient in data.ingredients ) {
        ingredients.push({
            ingredient,
            ...data.ingredients[ingredient]
        })
    }
    
    data.ingredients = ingredients;

    // turn the steps list (an object) into an array
    // We do not keep the id of each step in order to be able to request them to the api
    let steps = [];

    for( var step in data.steps ) {
        steps.push({
            ...data.steps[step]
        })
    }
    
    // we sort the steps by order since the sorting is not done by API
    // TODO ? sort steps at API level ?
    data.steps = steps.sort((a,b) => a.order - b.order);

    return data;
}

const cleanRecipesListFromApi = (data) => data.map((recipe) => cleanRecipeFromApi(recipe))

export const getRecipeDetail = async (id) => {
    try {
        // create the request content
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + apiKey
            })
        };

        let recipeDetailURI = recipesURI + '/' + id;

        // send request to the API
        // expected success status is 200
        let response = await fetch(recipeDetailURI, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // console.log(("Réponse de l'api : ", response));
        // turn the json into an object
        let data = await response.json();

        return cleanRecipeFromApi(data);
    } catch(err) {
        console.log("Erreur durant la requete de recette : ", err);
    }
}

export const postRecipeSearch = async (query) => {
    try {
        // create the request content
        let fetchParams = { 
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + apiKey,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(query)
        };

        let recipeSearchURI = recipesURI + '/search';

        // send request to the API
        // expected success status is 200
        let response = await fetch(recipeSearchURI, fetchParams);
        // console.log(("Réponse de l'api : ", response));
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();

        return cleanRecipesListFromApi(data);
    
    } catch(err) {
        console.log("Erreur durant la recherche de recette : ", err);
    }
}