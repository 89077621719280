import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import * as roles from '../../../constants/roles'
import { message, Row, Col, Tag } from 'antd'
import * as users from '../../../api/users'
import * as plannings from '../../../api/plannings'
import { PageHeader } from '../../Content/Layout/Headers'
import PlanningTable from '../../Content/Plannings/PlanningTable'
import { responsive } from '../../../tools/index'

const idCardResponsive = responsive(24);
const tastesResponsive = responsive(24);

const condition = auth => !!auth && typeof auth.roles !== 'undefined' && auth.roles[roles.ADMIN]

class UserDetailContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            plannings: [],
        }
    }

    async componentDidMount() {
        this.props.onLoading();

        try{
            let userId = this.props.match.params.id;

            // launch the two requests for getting the user's data
            let [ userDetail, userPlannings ] = await Promise.all([users.getUserDetail(userId), plannings.getPlannings(userId)]);

            this.setState({ user: userDetail, plannings: userPlannings});

        } catch(err) {
            message.error("Une erreur s'est produite lors de la récupération des information de l'utilisateur.");
        }

        this.props.onLoaded();
    }

    render() {
        if( !this.state.user ) {
            return <div></div>;
        };

        let {
            email,
            firstName,
            name,
            tastes
        } = this.state.user;

        return(
            <div id="admin-user-detail">
                <Row gutter={16}>
                    <PageHeader label={firstName + ' ' + name} />
                    <Col {...idCardResponsive}>
                        <div>{email}</div>
                    </Col>
                    <Col {...tastesResponsive} className="tastes">
                        <h2>Profil de l'utilisateur</h2>
                        <div className="dislikes">
                            <div className="ingredients">
                                <span>Ingrédients à éviter</span>
                                <div className="tags">
                                    {typeof tastes !== 'undefined' 
                                    && typeof tastes.dislikes.ingredients !== 'undefined'
                                    && tastes.dislikes.ingredients.map((ing) => 
                                        <Tag key={ing.id}>{ing.label}</Tag>    
                                    )}
                                </div>
                            </div>
                            <div className="recipes-categories">
                                <span>Types de recettes à éviter</span>
                                <div className="tags">
                                    {typeof tastes !== 'undefined' 
                                    && typeof tastes.dislikes.tags !== 'undefined'
                                    && tastes.dislikes.tags.map((ing) => 
                                        <Tag key={ing.id}>{ing.label}</Tag>    
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <h2>Liste des plannings</h2>
                        <PlanningTable user={this.state.user} plannings={this.state.plannings} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const UserDetail = templatePrivatePage(UserDetailContent, "Administration")

export default withAuthorization(condition)(UserDetail)