import React from 'react'
import { withRouter } from 'react-router-dom'
import AuthUserContext from './context'
import { auth } from '../firebase'
import * as routes from '../../constants/routes'
import * as users from '../../api/users'

const withAuthorization = (condition) => (Component) => {
    class WithAuthorization extends React.Component {
      
      componentDidMount() {
        this.listener = auth.onAuthStateChanged(
          async authUser => {
            try {
              if( authUser ) {
                let userDb = await users.getUserDetail(authUser.uid);
  
                // added this control to prevent some situations when the userDb is undefined without explanation
                // what makes the app crash. Rather than having a crash, I prefer to send the user to signin page
                // TODO : is it the best and safer solution ?
                if( typeof userDb === 'undefined' ) {
                  throw new Error("Erreur de récupération des informations de l'utilisateur.");
                }

                // default empty roles
                // TODO : reassess this test and default state
                if ( typeof userDb.roles === 'undefined' || !userDb.roles ) {
                  userDb.roles = {};
                }
  
                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...userDb,
                };
  
                if (!condition(authUser)) {
                  this.props.history.push(routes.HOME);
                }
              } else {
                this.props.history.push(routes.SIGNIN);
              }

            } catch(err) {
              console.log("Error during authent : ", err);
              this.props.history.push(routes.SIGNIN);
            }
          }
        );
      }
  
      componentWillUnmount() {
        this.listener();
      }
  
      render() {
        // in the render method, we push the authUser object as a prop of the rendered component
        // this will allow us to use this data in the component. Main usage : in the recipe matcher
        // Could be replaced by REDUX I guess
        return (
          <AuthUserContext.Consumer>
            {({authUser, update}) =>
              condition(authUser) ? <Component user={authUser} userUpdate={update} {...this.props} /> : null
            }
          </AuthUserContext.Consumer>
        );
      }
    }
  
    return withRouter(WithAuthorization);
  };
  
  export default withAuthorization