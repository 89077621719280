import React from 'react'
import { Row, Col, Form, InputNumber, Checkbox, Icon, Button } from 'antd'
import { CancelButton } from '../../Buttons/CancelButton'
import { Toolbar } from '../../Layout/Toolbar'
import { responsive } from '../../../../tools'
import ValidateButton from '../../Buttons/ValidateButton'
import './styles.css'
import { HelperText } from '../../Texts'

const respButton = responsive(12)

class MealsParamsFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCheckbox: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.switchDisplay = this.switchDisplay.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                this.props.onSubmit();
            }
        })
    }

    switchDisplay = () => 
        this.setState((state) => {
            return { showCheckbox: !state.showCheckbox }
        })

    render() {
        const { getFieldDecorator } = this.props.form;
        const { guests, guestsChoice } = this.props;
        const chosenNamedGuests = guestsChoice.map((id) => guests[id].firstName);
        
        const nbOfGuests = guestsChoice.length;
        const listString = nbOfGuests === 0 ? " " : chosenNamedGuests.join(', ');
        
        const classShow = this.state.showCheckbox ? "fullscreen" : "inflow";

        let guestsOptions = [];

        if( typeof guests !== 'undefined' && Object.keys(guests).length > 0 ) {
            Object.keys(guests).map((id) => guestsOptions.push({ label: guests[id].firstName + " " + guests[id].name, value: id }));
        }

        return(
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col>
                        <p>
                            Combien de recettes souhaites-tu sélectionner pour ton menu ?
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item>
                            {getFieldDecorator('mealsNb', {
                                rules: [{ required: true, message: 'Merci de saisir un nombre valide.' }],
                            })(
                                <InputNumber 
                                    min={1}
                                    max={7}
                                    className="meal-number"
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {guestsOptions.length === 0  &&
                
                <Row>
                    <Col>
                        <div>
                            Merci d'ajouter un convive dans le profil.
                        </div>
                    </Col>
                </Row>

                }
                {guestsOptions.length > 0 &&

                <div className={classShow}>
                    <Row className="guests-selection">
                        <Col className="oc-guests-list">
                            <p>Pour ces repas, il y aura <span className="strong">{nbOfGuests}</span> personne{nbOfGuests === 1 ? '' : 's'}</p>
                            <div className="field" onClick={this.switchDisplay}>
                                <Icon className="user" type="smile" /><div>{listString}</div>
                            </div>
                            <HelperText>
                                Ce paramètre sera appliqué à toutes les recettes que tu vas choisir, mais tu pourras 
                                facilement le modifier dans ton menu pour chaque repas.
                            </HelperText>
                        </Col>
                        <Form.Item className="guests-checkboxes">
                            {getFieldDecorator('guestsChoice', {
                                rules: [{ required: true, message: 'Merci de sélectionner au moins un convive' }]
                            })(
                                <Checkbox.Group>
                                    {guestsOptions.map(guest =>
                                    <Col key={guest.value} className="guest-checkbox">
                                        <Checkbox value={guest.value}>{guest.label}</Checkbox>
                                    </Col>
                                    )}
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                        <Col className="modal-actions">
                            <div className="add-guest" onClick={this.props.showAddGuestModal}>
                                <Icon className="icon" type="user-add" /><span>Ajouter</span>
                            </div>
                            <Button type="primary" onClick={this.switchDisplay}>Valider les convives</Button>
                        </Col>
                    </Row>
                </div>
                }
                <Toolbar className="funnel-toolbar">
                    <Col {...respButton}>
                        <Form.Item>
                            <div className="toolbar-action left-action">
                                <CancelButton onClick={this.props.onCancel} />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col {...respButton} >
                        <Form.Item>
                            <div className="toolbar-action right-action">
                                <ValidateButton />
                            </div>
                        </Form.Item>
                    </Col>
                </Toolbar>
            </Form>
        );
    }
}

const MealsParamsForm = Form.create({
    name : "meals_params_choice",
    mapPropsToFields(props) {
        return {
            mealsNb: Form.createFormField({
                value: props.mealsNb
            }),
            guestsChoice: Form.createFormField({
                value: props.guestsChoice
            }),
        }
    },
    onValuesChange(props, values) {
        props.onChange(values);
    },
})(MealsParamsFormComponent)

export default MealsParamsForm