import React from 'react'
import { Button, Modal, Icon } from 'antd'
import { auth } from '../../../services/firebase'

class SignOutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
        }
    }

    showModal = () => this.setState({ isModalVisible: true, })

    handleOk = () => {
        this.setState({ isModalVisible: false, });
        auth.doSignOut();
    }

    handleCancel = () => this.setState({ isModalVisible: false, })

    render() {
        return (
            <div className="sign-out-button">
                <Button type="danger" onClick={this.showModal}><Icon type="poweroff" />Déconnexion</Button>
                <Modal
                    title="Déconnexion d'Octave"
                    visible={this.state.isModalVisible}
                    maskClosable={false}
                    okText="Je me déconnecte"
                    okType="danger"
                    onOk={this.handleOk}
                    cancelText="Annulation"
                    onCancel={this.handleCancel}
                >
                    <p>Etes-vous sûr.e de vouloir vous déconnecter de l'application ?</p>
                </Modal>
            </div>
        )
    }
}

export default SignOutButton