import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../../constants/routes'
import { getMealDetail } from '../../../../api/meals'
import { message, Row, Col, Modal, Icon } from 'antd'
import * as tools from '../../../../tools'

import './styles.css'
import RecipeTile from '../../Recipes/RecipeTile'

const recipeResponsive = tools.responsive(24,24,12,8,8,8);
// const titleResponsive = tools.responsive(24);
// const imgResponsive = tools.responsive(8,8,8,24,24,24);
// const descResponsive = tools.responsive(16,16,16,24,24,24);

const GuestsDetails = (props) => 
    typeof props.nbOfGuests !== 'undefined' && 
    
    <div className="oc-meal-param">
        <Icon id="detail-icon" type="smile" theme="twoTone" twoToneColor="#008DD5" />
        <span>{props.nbOfGuests} personnes</span> 
        {/* <Icon id="edit-button" type="edit" theme="filled" /> */}
    </div>

const PlanningDetails = (props) => {
    let dayDisplay = 
        typeof props.day === 'undefined' 
        ? '-' 
        : tools.dayInFrench(props.day);

    return(
        <div className="oc-meal-param">
            <Icon id="detail-icon" type="calendar" theme="twoTone" twoToneColor="#008DD5" />
            <span>{dayDisplay}</span> 
            <Link to={routes.PLANNING_SCHEDULE(props.planning.id)}><Icon id="edit-button" type="edit" theme="filled" /></Link>
        </div>
    )
}

const MealDetail = (props) => {
    const {
        starter,
        main,
        side,
        guests,
        day,
        planning
    } = props.meal

    let nbOfGuests = typeof guests === 'undefined' || guests === null ? 0 : Object.keys(guests).length;

    return(
        <div className="oc-meal-detail">
            <Row className="oc-meal-params">
                <Col>
                    <GuestsDetails nbOfGuests={nbOfGuests} />
                </Col>
                <Col>
                    <PlanningDetails day={day} planning={planning} />
                </Col>
            </Row>
            <Row>
                {typeof starter !== 'undefined' 
                && starter !== null &&

                <Col {...recipeResponsive}>
                    <RecipeTile recipe={starter} />
                </Col>
                
                }
                {typeof main !== 'undefined' 
                && main !== null &&
                
                <Col {...recipeResponsive}>
                    <RecipeTile recipe={main} />
                </Col>
                
                }
                {typeof side !== 'undefined' 
                && side !== null &&
                
                <Col {...recipeResponsive}>
                    <RecipeTile recipe={side} />
                </Col>

                }
            </Row>
        </div>
    )
}

class MealModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meal: null,
        }

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => this.setState({ meal: null }, this.props.handleClose)

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message
        if( !!this.props.mealId ) {
            // retrieve the information about the planning
            // update the state with the new object    
            try {
                console.log("Id du repas : ", this.props.mealId);
                let mealFromApi = await getMealDetail(this.props.mealId);

                this.setState({
                    meal: mealFromApi
                }, () => {
    
                    let page = {
                        name: 'Repas / détail',
                        properties: {
                            mealId: this.state.meal.id,
                            menuId: this.state.meal.planning.id
                        }
                    }
    
                    this.props.trackPage(null , page);
                });

            } catch(err) {
                console.log("Erreur lors de la récupération du repas : ", err);
                message.error("Une erreur s'est produite lors de la récupération de votre repas. Merci de bien vouloir réessayer.")
            }
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if( !!this.props.mealId && this.props.mealId !== prevProps.mealId ) {
            // TODO : handle the case when the planning id is unknown, and the api returned a
            // 404 HTTP message

            // retrieve the information about the planning
            // update the state with the new object    
            try {
                console.log("Id du repas : ", this.props.mealId);
                let mealFromApi = await getMealDetail(this.props.mealId);

                this.setState({
                    meal: mealFromApi
                }, () => {
    
                    let page = {
                        name: 'Repas / détail',
                        properties: {
                            mealId: this.state.meal.id,
                            menuId: this.state.meal.planning.id
                        }
                    }
    
                    this.props.trackPage(this.props.user, page);
                });

            } catch(err) {
                console.log("Erreur lors de la récupération du repas : ", err);
                message.error("Une erreur s'est produite lors de la récupération de votre repas. Merci de bien vouloir réessayer.")
            }
        }
    }

    render() {
        const displayType = 
            !this.state.meal 
            ? 'Chargement...' 
            : (
                this.state.meal.mealType === 'dinner' 
                ? 'Ton dîner' 
                : 'Ton déjeuner'
            )

        return(
            <Modal 
                centered 
                className="meal-folio"
                bodyStyle={{ height: "calc(100vh - 100px)", overflowY: "scroll", }} 
                width="100%"
                title={displayType}
                visible={this.props.displayMeal}
                onCancel={this.handleClose}
                destroyOnClose={true}
                footer={null}
            >
                {!this.state.meal &&

                <div>Chargement du repas</div>
        
                }
                {!!this.state.meal &&

                <MealDetail meal={this.state.meal} />

                }
            </Modal>
        )
    }
}

export default MealModal