import React from 'react'
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { AuthUserContext } from '../../../services/sessions'
import * as routes from '../../../constants/routes'
import * as roles from '../../../constants/roles'
import SignOutButton from '../Buttons/SignOutButton'
// import UserAvatar from '../User/UserAvatar'

import { auth } from '../../../services/firebase'

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userTokenId: '',
            userId: ''
        }
    }

    async componentDidMount() {
        const userTokenId = await auth.getUserTokenId();
        const userId = auth.getUserId();

        this.setState({
            userTokenId: userTokenId,
            userId: userId
        });
    }
    
    render() {
        return(
            <AuthUserContext.Consumer>
            {({authUser, update}) =>
                <div className="mobile-menu">
                    {/* <UserAvatar /> */}
                    <Menu className="octave-menu">
                        <Menu.Item><Link to={routes.HOME}><Icon type="home" />Accueil</Link></Menu.Item>
                        <Menu.Item><Link to={routes.USER_PROFILE}><Icon type="user" />Mon compte</Link></Menu.Item>
                        {
                            !!authUser && authUser.roles[roles.ADMIN] && (
                                <Menu.Item><Link to={routes.ADMIN}><Icon type="lock" />Admin</Link></Menu.Item>
                        )}
                    </Menu>
                    <SignOutButton />
                    {process.env.NODE_ENV !== 'production' ?
                        <div style={{fontSize:'8px'}}>
                            <div>
                                <h3>User token Id : </h3>
                                <div>{this.state.userTokenId}</div>
                            </div>
                            <div>
                                <h3>User Id : </h3>
                                <div>{this.state.userId}</div>
                            </div>
                        </div>
                    : null}
                </div>
            }
            </AuthUserContext.Consumer>
        );
    }
}

const DesktopMenu = (props) =>
    <div className="desktop-menu">
        <AuthUserContext.Consumer>
        {({authUser, update}) => 
            <Menu className="octave-menu">
                <Menu.Item><Link to={routes.ORGA_HOME}>Organiser mes repas</Link></Menu.Item>
                {/* <Menu.Item><Link to={routes.SHOP_HOME}>Faire les courses</Link></Menu.Item>
                <Menu.Item><Link to={routes.COOK_HOME}>Cuisiner et partager</Link></Menu.Item> */}
                {!!authUser && authUser.roles[roles.ADMIN] &&
                <Menu.Item><Link to={routes.ADMIN}>Administration</Link></Menu.Item>
                }
            </Menu>
        }
        </AuthUserContext.Consumer>
    </div>

export {
    MobileMenu,
    DesktopMenu
}