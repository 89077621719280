import React from 'react'
import { Col, Table, Button } from 'antd'
import { responsive } from '../../../tools'

const tablesResp = responsive(24,24,24,12,12,8)

const getRecipeTitle = (recipe) => typeof recipe === 'undefined' ? '-' : recipe.title;
const getRecipeId = (recipe) => typeof recipe === 'undefined' ? '' : recipe.id;
const getRecipeImgUrl = (recipe) => typeof recipe === 'undefined' ? '' : recipe.imgUrl;
const getRecipeDetail = (recipe) => ({ value: getRecipeId(recipe), title: getRecipeTitle(recipe), imgUrl: getRecipeImgUrl(recipe) })

const DinnersTable = (props) => {
    const columns = [
        {
            title: 'Entrée',
            dataIndex: 'starter',
            render: (text, record) => getRecipeTitle(record.starter)
        }, {
            title: 'Plat principal',
            dataIndex: 'main',
            render: (text, record) => getRecipeTitle(record.main)
        }, {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => 
                <Button 
                    type="primary"
                    shape="circle"
                    icon="edit" 
                    onClick={() => 
                            props.onAction(
                                "dinner", 
                                record.id, 
                                getRecipeDetail(record.main), 
                                getRecipeDetail(record.starter)
                            )}
                />
        }
    ]

    let data = props.dinners;

    return(
        <Col {...tablesResp}>
            <div>
                <h3>Liste des dîners</h3>
            </div>
            <Table
                rowKey={(record)=>record.id}
                size="small"
                dataSource={data}
                columns={columns}
                pagination={false} 
            />
            <Button 
                type="primary" 
                icon="plus" 
                onClick={() => props.onAction("dinner")}
            >Ajouter un dîner</Button>
        </Col>
    );
}

const LunchesTable = (props) => {
    const columns = [
        {
            title: 'Entrée',
            dataIndex: 'starter',
            render: (text, record) => getRecipeTitle(record.starter)
        }, {
            title: 'Plat principal',
            dataIndex: 'main',
            render: (text, record) => getRecipeTitle(record.main)
        }, {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => 
                <Button 
                    type="primary" 
                    shape="circle" 
                    icon="edit" 
                    onClick={() => 
                        props.onAction(
                            "lunch", 
                            record.id, 
                            getRecipeDetail(record.main), 
                            getRecipeDetail(record.starter)
                        )}
                />
        }
    ];

    let data = [];

    if( typeof props.lunches !== 'undefined' ) {
        data = Object.keys(props.lunches).map((mealId) => ({
            mealId: mealId,
            ...props.lunches[mealId]
        }));
    }

    return(
        <Col {...tablesResp}>
            <div>
                <h3>Liste des déjeuners</h3>
            </div>
            <Table
                rowKey={(record)=>record.mealId}
                size="small"
                dataSource={data}
                columns={columns}
                pagination={false} 
            />
            <Button
                type="primary"
                icon="plus"
                onClick={() => props.onAction("lunch")}
            >Ajouter un déjeuner</Button>
        </Col>
    );
}

export {
    DinnersTable,
    LunchesTable
}