import React from 'react'
import { withAuthorization } from '../../../services/sessions'
import templatePrivatePage from '../Templates/PrivatePage'
import { getRecipeDetail } from '../../../api/recipes'
import { message, Row, Col, Tabs } from 'antd'
import { MainToolbar } from '../../Content/Layout/Toolbar'
import IngredientsList from '../../Content/Recipes/IngredientsList'
import StepsList from '../../Content/Recipes/StepsList';
import RecipeDescription from '../../Content/Recipes/RecipeDescription'
import { RecipeTimes } from '../../Content/Recipes/RecipeTimes'
import RecipeDifficulty from '../../Content/Recipes/RecipeDifficulty'

const condition = auth => !!auth

const { TabPane } = Tabs

const imgResponsive = {
    xs: 8,
    sm: 8,
    md: 8,
    lg: 8,
    xl: 6,
    xxl: 6
}

const descResponsive = {
    xs: 16,
    sm: 16,
    md: 16,
    lg: 16,
    xl: 18,
    xxl: 18
}

const timeResponsive = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12
}

const diffResponsive = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12
}

class RecipeDetailContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: null,
        }
    }

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message

        this.props.onLoading();
        // retrieve the information about the planning
        // update the state with the new object    
        try {
            let recipeFromApi = await getRecipeDetail(this.props.match.params.id);
            // console.log("Recette récupérée : ", recipeFromApi);
            this.setState({
                recipe: recipeFromApi,
            }, () => {
                let page = {
                    name: 'Recette / détail',
                    properties: {
                        recipeId: this.state.recipe.id
                    }
                }

                this.props.trackPage(this.props.user, page);
            });
        } catch(err) {
            console.log("Erreur lors de la récupération de la recette...", err);
            message.error("Une erreur s'est produite lors de la récupération de votre planning. Merci de bien vouloir réessayer.")
        }
        this.props.onLoaded();
    }

    render() {
        let { recipe } = this.state
        
        if( !recipe ) {
            return <div>Recette en cours de chargement...</div>
        } else {
            return(
                <div>
                    <Row>
                        <Col>
                            <h2>{recipe.title}</h2>
                        </Col>
                    </Row>
                    <Row 
                        className="recipe-idcard"
                        type="flex"
                        justify="center"
                        gutter={16}
                    >
                        <Col {...imgResponsive}>
                            <img src={recipe.imgUrl} alt="notre délicieuse recette" />
                        </Col>
                        <Col {...descResponsive}>
                            <RecipeDescription recipe={recipe} />
                        </Col>
                        <Col {...timeResponsive}>
                            <RecipeTimes recipe={recipe} />
                        </Col>
                        <Col {...diffResponsive}>
                            <RecipeDifficulty recipe={recipe} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs>
                                <TabPane tab="Ingrédients" key="1">
                                    <IngredientsList ingredients={recipe.ingredients} />
                                </TabPane>
                                <TabPane tab="Préparation" key="2">
                                    <StepsList steps={recipe.steps} />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

const RecipeDetail = templatePrivatePage(RecipeDetailContent, "Recettes d'Octave", null, MainToolbar)

export default withAuthorization(condition)(RecipeDetail)
