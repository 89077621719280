import React from 'react'
import { Form, Input, DatePicker, Col, Button, Icon, Tooltip } from 'antd'
import { Toolbar } from '../../Content/Layout/Toolbar'
import BackHomeButton from '../../Content/Buttons/BackHomeButton'
import locale from 'antd/es/date-picker/locale/fr_FR'
import 'moment/locale/fr'

class MenuCreationFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // launch field validation in order to disable the submit button
        this.props.form.validateFields();
    }

    hasErrors = (fieldsError) => Object.keys(fieldsError).some(field => fieldsError[field])

    handleSubmit = (e) => {
        e.preventDefault();
        
        this.props.form.validateFields(async (err, values) => {
            if( !err ) {
                await this.props.onSubmit(values);
            }
        });
    }

    render() {
        const { getFieldDecorator, getFieldsError, isFieldTouched, getFieldError } = this.props.form;

        const orgaLabelError = isFieldTouched('orgalabel') && getFieldError('orgalabel');

        const helperText = 
            `La date de début est une information optionnelle. Elle te permettra de débloquer la fonctionnalité calendrier 
            de ton menu et de planifier tes recettes à partir de cette date.`;

        return (
            <Form className="octave-form" hideRequiredMark={false} onSubmit={this.handleSubmit}>
                <Form.Item
                    validateStatus={orgaLabelError ? 'error' : ''}
                    help={orgaLabelError || ''}
                >
                {getFieldDecorator('orgalabel', {
                        rules: [{ required: true, message: 'Merci de nommer votre menu' }],
                    })(
                    <Input placeholder="Nom du menu" max={26} />
                )}
                </Form.Item>
                <Form.Item>
                {getFieldDecorator('startDate', {
                        rules: [{ required: false }],
                    })(
                    <DatePicker 
                        showTime={false} 
                        locale={locale} 
                        format="DD/MM/YYYY" 
                        placeholder="1er jour du menu"
                    />
                )}
                    <Tooltip title={helperText} trigger="click" placement="topRight" >
                        <Icon className="helper" type="question-circle" theme="twoTone" />
                    </Tooltip>
                </Form.Item>
                <Toolbar className="funnel-toolbar">
                    <Col span={12}>
                        <div className="toolbar-action left-action">
                            <BackHomeButton>Annuler</BackHomeButton>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="toolbar-action right-action">
                            <Button 
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError())}
                            >
                                Créer le menu
                            </Button>
                        </div>
                    </Col>
                </Toolbar>
            </Form>
        );
    }
}

const MenuCreationForm = Form.create({name: 'menu_crea_form'})(MenuCreationFormComponent)

export default MenuCreationForm