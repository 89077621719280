import React from 'react'
import { Row, Col } from 'antd'
import LinkButton from '../../Content/Buttons/LinkButton'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import { PlanningCardWithToolbar } from '../../Content/Plannings/PlanningCard'
import { ReactComponent as NoPlanningIllustration } from '../../../img/MobileIdeas.svg'

const NoPlanning = (props) =>
    <Col>
        <div className="empty-planning">
            <NoPlanningIllustration />
            <p>
                Tu n'as pas encore créé de menu. Un menu est une liste de recettes sélectionnées pour ta semaine. 
                Cette sélection te permettra ensuite de faire les courses.
            </p>
            <LinkButton 
                to={routes.MENU_CREATE}
                type="primary"
            >
                Créer mon premier menu
            </LinkButton>
        </div>
    </Col>

const LastPlanning = (props) =>
    <Col>
        <PlanningCardWithToolbar planning={props.lastPlanning} />
        <Link className="category-link" to={routes.ORGA_HOME}>Voir tous mes menus</Link>
    </Col>

const CurrentPlanning = (props) => {
    const { lastPlanning } = props;
    const hasPlanning = typeof lastPlanning !== 'undefined' && !!lastPlanning && Object.keys(lastPlanning).length !== 0;

    return (
        <Row className="planning-section last-planning">
            <Col>
                <h2><span role="img" aria-label="book">📖  </span>Ton dernier menu</h2>
            </Col>
            {!hasPlanning 
                ? <NoPlanning />
                : <LastPlanning lastPlanning={lastPlanning} />
            }
        </Row>
    );
}

export default CurrentPlanning
