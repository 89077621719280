import React from 'react'
import { Row, Col, Icon, Button } from 'antd'
import AccountButton from '../Buttons/AccountButton'
import { Title } from '../Texts'
import Logo from '../Logo/Logo'
import { OctaveLogo } from '../Logo/SimpleLogo'
import { responsive } from '../../../tools'
import LinkButton from '../Buttons/LinkButton'
import { withRouter } from 'react-router-dom'

const titleResponsive = responsive(24,224,0,0,0,0)
const chatResponsive = responsive(0,0,0,0,0,0)
const logoResponsive = responsive(0,0,23,23,23,23)
const accountButtonResponsive = responsive(0,0,1,1,1,1)

const MobileHeaderComponent = (props) => 
    <Row
        type="flex"
        align="middle"
        className="octave-header mobile-header"
    >
        <Col {...titleResponsive}>
            { props.back &&
                <LinkButton className="back-button" to={props.back} type="ghost" icon="left"></LinkButton>
            }
            {!props.back && props.title !== 'Octave' &&
                <Button className="back-button" type="ghost" icon="left" onClick={props.history.goBack} />
            }
            {props.title === 'Octave' &&
                <OctaveLogo className="octave-logo" />
            }
            <Title>{props.title}</Title>
        </Col>
        <Col {...chatResponsive}>
            <div className="drift-open-chat">
                <Icon 
                    type="message"
                    theme="filled"
                    style={{color: "white", fontSize: '20px' }}
                />
            </div>
        </Col>
    </Row>

const MobileHeader = withRouter(MobileHeaderComponent)

const DesktopHeader = () =>
    <Row
        type="flex"
        align="middle"
        className="octave-header desktop-header"
    >
        <Col {...logoResponsive}>
            <Logo />
        </Col>
        <Col 
            {...accountButtonResponsive}
        >
            <AccountButton />
        </Col>
    </Row>

const OctaveHeader = (props) =>
    <div>
        <MobileHeader title={props.title} onToggle={props.onToggle} back={props.back} />
        <DesktopHeader />
    </div>

export default OctaveHeader