function responsive(xs, sm, md, lg, xl, xxl) {
    if( arguments.length === 1 ) {
        return(
            {
                xs: xs,
                sm: xs,
                md: xs,
                lg: xs,
                xl: xs,
                xxl: xs,
            }
        )
    } else {
        return(
            {
                xs: xs,
                sm: sm,
                md: md,
                lg: lg,
                xl: xl,
                xxl: xxl,
            }
        )
    }
}

const weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
]

const modulo = (m, n) => {
    let r = ((m % n) + n) % n;
    return r < 0 ? m + Math.abs(n) : r;
}

const nDaysShift = (day, n) => {
    let indexDay = weekDays.indexOf(day);

    if( indexDay >= 0 ) {
        return weekDays[ modulo(indexDay + n,7) ]
    } else {
        return;
    }
}

const nextDay = (day) => nDaysShift(day, 1)
const theDayAfter = (day) => nDaysShift(day, 1)

const lastScheduleDay = (day) => nDaysShift(day, 6)

const previousDay = (day) => nDaysShift(day, -1)
const theDayBefore = (day) => nDaysShift(day, -1)

const dayInFrench = (day) => {
    switch(day) {
        case 'monday' :
            return "lundi";
        case 'tuesday' :
            return "mardi";
        case 'wednesday' :
            return "mercredi"
        case 'thursday' :
            return "jeudi"
        case 'friday' :
            return "vendredi"
        case 'saturday' :
            return "samedi"
        case 'sunday' :
            return "dimanche"
        default:
            return null;
    }
}

const capitalizedDayInFrench = (day) => dayInFrench(day).charAt(0).toUpperCase()+dayInFrench(day).slice(1)

const isMealScheduled = (meal) => typeof meal.day !== 'undefined'

const translateDifficulty = (difficulty) => {
    if( typeof difficulty === 'undefined' ) {
        return 'facile';
    } else {
        switch(difficulty) {
            case 'easy':
                return 'facile';
            case 'medium':
                return 'moyenne';
            case 'difficult':
                return 'difficile';
            default:
                return null;
        }
    }
}

export {
    responsive,
    nextDay,
    previousDay,
    theDayAfter,
    theDayBefore,
    lastScheduleDay,
    dayInFrench,
    capitalizedDayInFrench,
    isMealScheduled,
    translateDifficulty
}