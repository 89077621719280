import React from 'react'
import templateOnboardingPage from '../../Templates/OnboardingPage'
import { withAuthorization } from '../../../../services/sessions'
import { PageHeader } from '../../../Content/Layout/Headers'
import { Row, Col, Icon } from 'antd'
import { Step2Cover } from '../../../Content/Layout/PageCover'
import { LinkButton } from '../../../Content/Buttons/LinkButton'
import { Toolbar } from '../../../Content/Layout/Toolbar'
import * as routes from '../../../../constants/routes'

const condition = (auth) => !!auth

class PageContent extends React.Component {
    componentDidMount() {
        let page = { name: 'Onboarding / Step 2' };
        this.props.trackPage(this.props.user, page);
    }

    render() {
        return(
            <div className="withToolbar">
                <Step2Cover />
                <PageHeader label="Une organisation simple" />
                <Row className="content">
                    <Col>
                        <p>
                            Avec moi, manger varié est vraiment très facile. Je te proposerai des recettes 
                            qui correspondent à ce que ta famille et toi aimez.
                        </p>
                        <p>
                            Ces recettes seront regroupées dans un menu qui sera ta feuille de route pour la semaine.
                        </p>
                        <p>
                            Tu feras tes courses en fonction des recettes ajoutées à ce menu.  Ainsi, une fois en cuisine, tu auras tous 
                            les ingrédients sous la main pour préparer de bons plats.
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

const PageFooter = (props) => 
    <Toolbar className="funnel-toolbar">
        <Col span={12}>
            <div className="toolbar-action left-action">
                <LinkButton to={routes.ONBOARDING_STEP1} type="default"><Icon type="step-backward" />  Retour</LinkButton>
            </div>
        </Col>
        <Col span={12}>
            <div className="toolbar-action right-action">
                <LinkButton to={routes.ONBOARDING_STEP3} type="primary">Suite <Icon type="step-forward" /></LinkButton>
            </div>
        </Col>
    </Toolbar>


const OnboardingStep2 = templateOnboardingPage(PageContent, PageFooter)

export default withAuthorization(condition)(OnboardingStep2)