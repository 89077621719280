import React from 'react'
import { Row, Col } from 'antd'
import { responsive } from '../../../tools'
import RecipeDifficulty from './RecipeDifficulty'
import { RecipeOverallDuration } from './RecipeTimes'

const RecipeCover = (props) =>
    <div className="recipe-img" style={{backgroundImage: "url("+props.url+")", }} />


const Labels = (props) => {
    const colResponsive = responsive(12);

    const difficulty = props.difficulty;

    return (
        <Row className="recipe-labels">
            <Col className="time" {...colResponsive}>
                <div>
                    <RecipeOverallDuration value={props.time} />
                </div>
            </Col>
            <Col className="difficulty" {...colResponsive}>
                <RecipeDifficulty value={difficulty} />
            </Col>
        </Row>
    );
}

const Description = (props) => 
    <Row>
        <Col>
            <p>{props.desc}</p>
        </Col>
    </Row>

const Ingredients = (props) => 
    <Row>
        <Col>
            <h4>Ingrédients</h4>
        </Col>
        <Col>
            <ul>
                {props.ingredients.map((item) => 
                    <li key={item.ingredient}>{item.name}</li>
                )}
            </ul>
        </Col>   
    </Row>

const RecipeCard = (props) => {
    const { 
        preparationTime,
        cookingTime,
        restingTime,
        difficulty
    } = props.recipe;

    const totalTime = preparationTime + cookingTime + restingTime;

    return(
        <Row className="recipe-card">
            <Col>
                <h3>{props.recipe.title}</h3>
                <RecipeCover url={props.recipe.imgUrl} />
                <Labels time={totalTime} difficulty={difficulty} />
                <Description desc={props.recipe.description} />
                <Ingredients ingredients={props.recipe.ingredients} />
            </Col>
        </Row>
    );
}

const MatchedRecipeCard = (props) => 
    <Row className="recipe-card">
        <Col className="matched">
            <h3>{props.recipe.title}</h3>
            <RecipeCover url={props.recipe.imgUrl} />
            <div className="title">Excellent choix !</div>
            {props.remainder === 0
                ? <p>Ça y est, tu as choisi toutes tes recettes !! <span role="img" aria-label="raised_hands">🙌</span></p> 
                : (props.remainder === 1
                    ? <p>Plus qu'une recette à choisir pour ton menu.</p> 
                    : <p>Plus que {props.remainder} recettes à choisir pour ton menu.</p>
                )
            }
        </Col>
    </Row>

const DiscardedRecipeCard = (props) => 
    <Row className="recipe-card">
        <Col className="discarded">
            <h3>{props.recipe.title}</h3>
            <RecipeCover url={props.recipe.imgUrl} />
            <div className="title">Dommage !</div>
            {props.remainder === 1
                ? <p>Plus qu'une recette à choisir pour ton menu. Nous y sommes presque.</p> 
                : <p>Encore {props.remainder} recettes à trouver. Pas d'inquiétude, j'ai d'autres idées à te proposer.</p>
            }
        </Col>
    </Row>

export { 
    RecipeCard,
    MatchedRecipeCard,
    DiscardedRecipeCard
}