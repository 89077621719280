import React from 'react'


const Ingredient = (props) => 
    <li className="ingredient-detail" >
        <span className="name">{props.ingredient.name}</span>
        <span className="quantity">{props.ingredient.quantity}</span>
        {
            !props.ingredient.unitLabel 
            ?
                null
            :
                <span className="unit">{props.ingredient.unitLabel}</span>
        }
    </li>

const IngredientsList = (props) =>
    <ul className="ingredients-list">
        {props.ingredients.map((ingredient) => 
            <Ingredient ingredient={ingredient} key={ingredient.ingredient} />
        )}
    </ul>

export default IngredientsList