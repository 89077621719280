import React from 'react'
import { Row, Col } from 'antd'
import { responsive } from '../../../tools'
import { CardButton, PlanButton, ShopButton, HomeButton, UserButton, MenusButton, ChatButton, SettingsButton } from '../Buttons/ToolbarButtons'
import AddPlanningButton from '../Buttons/AddPlanningButton'
import { withRouter } from 'react-router-dom'
import * as routes from '../../../constants/routes'

const actionResponsive = responsive(4)
const actionPlanningResponsive = responsive(6)

const ToolbarAddPlanningButton = (props) =>
    <div className="toolbar-action">
        <AddPlanningButton />
    </div>

// TODO : wrap children into COL with the help of React.Children.map 
const Toolbar = ({type = "flex", justify = "center", align = "middle", gutter = 0, className = "", ...props}) =>
    <Row
        className={className.length === 0 ? "toolbar" : className}
        type={type}
        justify={justify}
        align={align}
        gutter={gutter}
    >
        {props.children}
    </Row>

const MainToolbarComponent = (props) => {
    let { history } = props;

    const toHome = () => history.push(routes.HOME);
    const toMenus = () => history.push(routes.ORGA_HOME);
    const toUserProfile = () => history.push(routes.USER_PROFILE);

    return(
        <Toolbar className="main-toolbar" justify="space-around">
            <Col {...actionResponsive}>
                <HomeButton onClick={toHome} />
            </Col>
            <Col {...actionResponsive}>
                <MenusButton onClick={toMenus} />
            </Col>
            <Col {...actionResponsive}>
                <ToolbarAddPlanningButton />
            </Col>
            <Col {...actionResponsive}>
                <UserButton onClick={toUserProfile} />
            </Col>
            <Col {...actionResponsive}>
                <ChatButton className="drift-open-chat" />
            </Col>
        </Toolbar>
    )
}

const MainToolbar = withRouter(MainToolbarComponent)

const MenuToolbarComponent = (props) => {
    let { history } = props;

    const toDetail = () => history.push(routes.PLANNING_DETAIL(props.planningId));
    const toSchedule = () => history.push(routes.PLANNING_SCHEDULE(props.planningId));
    const toSettings = () => history.push(routes.PLANNING_EDIT(props.planningId));

    return(
        <Toolbar className="menu-toolbar" type="flex" justify="space-around" align="bottom">
            <Col {...actionPlanningResponsive}>
                <CardButton 
                    className={props.list ? "active" : "inactive"} 
                    onClick={toDetail} 
                />
            </Col>
            <Col {...actionPlanningResponsive}>
                <PlanButton 
                    className={props.schedule ? "active" : "inactive"} 
                    onClick={toSchedule}
                />
            </Col>
            <Col  {...actionPlanningResponsive}>
                <ShopButton 
                    className={props.shop ? "active" : "inactive"} 
                    onClick={toSchedule}
                />
            </Col>
            <Col {...actionPlanningResponsive}>
                <SettingsButton
                    className={props.settings ? "active" : "inactive"} 
                    onClick={toSettings}
                />
            </Col>
        </Toolbar>
    );
}

const MenuToolbar = withRouter(MenuToolbarComponent)

const MenuMiniToolbarComponent = (props) => {
    let {
        id,
        mainPage
    } = props;

    const toDetail = () => props.history.push(routes.PLANNING_DETAIL(id));
    const toSchedule = mainPage ? toDetail : () => props.history.push(routes.PLANNING_SCHEDULE(id));
    const toSettings = mainPage ? toDetail : () => props.history.push(routes.PLANNING_EDIT(id));

    return(
        <div className="mini-toolbar">
            <CardButton onClick={toDetail} />
            <PlanButton onClick={toSchedule} />
            <ShopButton onClick={toSchedule} />
            <SettingsButton onClick={toSettings} />
        </div>
    )
}

const MenuMiniToolbar = withRouter(MenuMiniToolbarComponent)

export {
    Toolbar,
    MainToolbar,
    MenuToolbar,
    MenuMiniToolbar
}