import React, { Fragment } from 'react'

// use the config that fits the current environment
const segmentKey = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_SEGMENT_PROD_KEY
  : process.env.REACT_APP_SEGMENT_DEV_KEY;

const createPlanningTracking = (pageName, planning) => {
  const {
    id,
    label,
    lunches,
    dinners
  } = planning

  const isMenuEmpty = 
      (!dinners || dinners.length === 0) 
      && (!lunches || lunches.length === 0);

  return(
    {
      name: pageName,
      properties: {
          menuId: id,
          menuLabel: label,
          isEmpty: isMenuEmpty
      }
    }
  )
}
class SegmentSnippet extends React.Component {
    constructor(props) {
      super(props);
  
      this.addMainScript = this.addMainScript.bind(this);
      this.insertScript = this.insertScript.bind(this);
    }
  
    insertScript(scriptText) {
      const script = document.createElement("script");
      script.innerText = scriptText;
      script.async = true;
      document.body.appendChild(script);
    }
    
    addMainScript() {
      const scriptText = `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
        analytics.load('${segmentKey}');
        }}();`;
  
      this.insertScript(scriptText);
    }

    componentDidMount() {
      if (typeof window !== "undefined" && !window.analytics) {
        this.addMainScript();
      }
    }
  
    render() {
      return <Fragment />;
    }
}

export {
  SegmentSnippet,
  createPlanningTracking
} 