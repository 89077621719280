import React from 'react'
import { Icon } from 'antd'
import * as tools from '../../../../tools'
import './styles.css'

const RecipeDifficulty = (props) => {
    let difficulty = tools.translateDifficulty(props.value);

    let color = 
        difficulty === "facile" 
        ? "#6CC566" 
        : (difficulty === "moyenne")
            ? "#008DD5"
            : "#EE6C4D";

    return (
        <div className="oc-recipe-difficulty">
            <Icon className="icon" type="fire" theme="filled" style={{ color: color }} /><span>{difficulty}</span>
        </div>
    );
}

export default RecipeDifficulty