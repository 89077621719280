import React from 'react'
import { withAuthorization } from '../../../services/sessions'
import templatePrivatePage from '../Templates/PrivatePage'
import * as planningsApi from '../../../api/plannings'
import { message, Row } from 'antd'
import * as routes from '../../../constants/routes'
import { EditablePageHeader } from '../../Content/Layout/Headers'
import DayEdit from '../../Content/Plannings/DayEdit'
import { createPlanningTracking } from '../../../tools/Segment'
import { MainToolbar, MenuToolbar } from '../../Content/Layout/Toolbar'


const condition = auth => !!auth

class PlanningEditContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
            isLoading: true,
        }

        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
    }

    handleTitleChange = async (newLabel) => {
        this.props.onLoading();

        try { 
            let updatedPlanning = await planningsApi.doRenamePlanning(this.state.planning.id, newLabel);

            if( typeof updatedPlanning !== 'undefined' && updatedPlanning ) {
                let planning = {...this.state.planning};
                planning.label = newLabel;

                this.setState({ 
                    planning: planning, 
                },
                    () => this.props.setPageTitle(this.state.planning.label)
                );

                message.success("Planning mis à jour");
            }
        } catch(err) {
            message.error("Une erreur s'est produite lors de la mise à jour du menu.")
        }

        this.props.onLoaded();
    }

    handleDayChange = async (newDay) => {
        this.props.onLoading();

        try { 
            let updatedPlanning = await planningsApi.doChangeFirstDay(this.state.planning.id, newDay);

            console.log('Updated planning : ', updatedPlanning);
            if( typeof updatedPlanning !== 'undefined' && updatedPlanning ) {
                let planning = {...this.state.planning};
                
                if( !newDay ) {
                    delete planning.firstDay;
                    delete planning.startDate;
                } else {
                    planning.firstDay = updatedPlanning.firstDay;
                    planning.startDate = updatedPlanning.startDate;
                }

                this.setState({ planning: planning, });

                message.success("Planning mis à jour");
            }
        } catch(err) {
            message.error("Une erreur s'est produite lors de la mise à jour du menu.")
        }

        this.props.onLoaded();
    }

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message
        this.setState({ isLoading: true, });
        this.props.onLoading();

        let planningId = this.props.match.params.id;

        this.props.setBackpage(routes.PLANNING_DETAIL(planningId));
        // retrieve the information about the planning
        // update the state with the new object    
        try {
            let planningFromApi = await planningsApi.getPlanningDetail(planningId);
            // console.log("Planning récupéré : ",planningFromApi);
            this.setState({
                planning: planningFromApi,
                isLoading: false,
            },
                () => this.props.setPageTitle(this.state.planning.label)
            );

            // track the page and user
            let pageTracking = createPlanningTracking("Menu / édition", this.state.planning);

            this.props.trackPage(this.props.user, pageTracking);

        } catch(err) {
            message.error("Une erreur s'est produite lors de la récupération de votre planning. Merci de bien vouloir réessayer.");
            this.setState({ isLoading: false, });
        }

        this.props.onLoaded();
    }

    render() {
        if( !this.state.planning ) {
            return <div>Chargement du menu en cours...</div>
        } else {
            let {
                label,
                startDate,
                id
            } = this.state.planning;

            return(
                <div id="menu" className="planning-edit">
                    <MenuToolbar settings planningId={id} />
                    <EditablePageHeader value={label} onTitleChange={this.handleTitleChange} />
                    <Row>
                        <DayEdit day={startDate} onChange={this.handleDayChange} />
                    </Row>
                </div>
            )
        }
    }
}

const PlanningEdit = templatePrivatePage(PlanningEditContent, "", null, MainToolbar)

export default withAuthorization(condition)(PlanningEdit)