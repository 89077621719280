import React from 'react'
import { Icon } from 'antd'

const MatchButton = (props) =>
    <div className="toolbar-action">
        <button 
            className="recipe-matcher--button match--button"
            onClick={props.onClick}
        >
            <Icon type="check" />
        </button>
    </div>

const DiscardButton = (props) =>
    <div className="toolbar-action">
        <button 
            className="recipe-matcher--button discard--button"
            onClick={props.onClick}
        >
            <Icon type="close" /> 
        </button>
    </div>

const RewindButton = (props) => 
    <div className="toolbar-action left-action">
        <button 
            className="recipe-matcher--button rewind--button"
            onClick={props.onClick}
        >
            <Icon type="undo" /> 
        </button>
    </div>

const NextButton = (props) => 
    <div className="toolbar-action right-action">
        <button 
            className="recipe-matcher--button next--button"
            onClick={props.onClick}
        >
            <Icon type="step-forward" /> 
        </button>
    </div>

export { 
    MatchButton,
    DiscardButton,
    RewindButton,
    NextButton
}