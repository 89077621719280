import React from 'react'
import LinkButton from '../Buttons/LinkButton'
import * as routes from '../../../constants/routes'

const PlaceholderButton = ({id, ...props}) => 
    <div className="placeholder" {...props}>
        <div id={id} />
        <LinkButton type="primary" to={props.to}>{props.children}</LinkButton>
    </div>

const PlanningPlaceholder = props =>
    <PlaceholderButton {...props}
        id="ph-planning"
        to={routes.MENU_CREATE}
    >
        Créer un nouveau menu
    </PlaceholderButton>

export default PlanningPlaceholder