import React from 'react'

import { Row, Col, message } from 'antd'
import templatePrivatePage from '../Templates/PrivatePage';
import { withAuthorization } from '../../../services/sessions';
import BackHomeButton from '../../Content/Buttons/BackHomeButton'
import PlanningPlaceholder from '../../Content/Buttons/PlaceholderButton'
import { getPlannings } from '../../../api/plannings'
import PlanningList from '../../Content/Plannings/PlanningList'

const condition = auth => !!auth

const colResponsive = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
}

class CookHomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plannings: [],
        }
    }

    async componentDidMount() {
        // start loading animation
        this.props.onLoading();
        
        // fetch data from the database : list of plannings for one user
        try {
            let plannings = await getPlannings();
            
            // filter only by displaying plannings that are in 'validated' status
            // TODO : add an argument to the api call so that we only request planning with 
            // this status
            plannings = plannings.filter(planning => planning.status === 'validated');
            // sort the plannings by creation date descending
            plannings.sort((a, b) => b.createdAt - a.createdAt);

            this.setState({
                plannings: plannings,
            });
        } catch(err) {
            message.error("Une erreur s'est produite lors du chargement des menus. Merci de recharger la page.")
        }
        // end the page loading
        this.props.onLoaded();
    }

    render() {
        if( !this.state.plannings || this.state.plannings.length === 0 ) {
            return(
                <div>
                    <Row>
                        <Col>
                            <h2>Aucun menu en cours</h2>
                            <p>Vous pouvez organiser vos repas par période que ce soit pour une semaine ou quelques jours.</p>
                        </Col>
                    </Row>
                    <Row 
                        type="flex"
                        gutter={8}
                        justify="center"
                        align="top"
                    >
                        <Col {...colResponsive}>
                            <PlanningPlaceholder />
                            <BackHomeButton>Annuler</BackHomeButton>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return(
                <div>
                    <Row>
                        <Col>
                            <h2>Vos menus</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PlanningList dataSource={this.state.plannings} />
                        </Col>
                    </Row>
                </div>
            );
        }
    }
} 

const CookHome = templatePrivatePage(CookHomeContent, "Cuisiner")

export default withAuthorization(condition)(CookHome)