import React from 'react'
import { Row, Col } from 'antd'
import { responsive } from '../../../tools'
import { withRouter } from 'react-router-dom'
import * as routes from '../../../constants/routes'

const imgResp = responsive(6,6,8,10,24,24)
const titleResp = responsive(18,18,16,14,24,24)

const RecipePreview = (props) => {
    let bgImgUrl = `url(${props.recipe.imgUrl})`
    const goToMealDetail = () => props.history.push(routes.MEAL_DETAIL(props.mealId));

    return(
        // cannot be clicked if no meal Id has been passed
        <Row className="recipe-preview" gutter={16} onClick={props.clickable && typeof props.mealId !== 'undefined' ? goToMealDetail : null}>
            <Col {...imgResp}>
                <div className="thumbnail" style={{ backgroundImage: bgImgUrl }}></div>
            </Col>
            <Col {...titleResp}>
                <div className="recipe-title">{props.recipe.title}</div>
            </Col>
        </Row>
    );
}

export default withRouter(RecipePreview)