import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import * as roles from '../../../constants/roles'
import { Row, message } from 'antd'
import { PageHeader } from '../../Content/Layout/Headers'
import MealForm from './MealForm'
import { DinnersTable, LunchesTable } from './MealsTable'
import * as recipes from '../../../api/recipes'
import * as plannings from '../../../api/plannings'

const condition = auth => !!auth && typeof auth.roles !== 'undefined' && auth.roles[roles.ADMIN]

const defaultValue = { value: '', }

class PlanningAdminContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
            isModalVisible: false,
            isModalLoading: false,
            formInitialStarterId: {...defaultValue},
            formInitialMainId: {...defaultValue},
            formMealType: "",
            formMealId: "",
            formStarterId: {...defaultValue},
            formMainId: {...defaultValue },
        }

        this.initModal = this.initModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveFormRef = this.saveFormRef.bind(this);
    }

    async componentDidMount() {
        this.props.onLoading();

        let {
            planning,
            user
        } = this.props.location.state;

        try{
            let planningFromApi = await plannings.getPlanningDetail(planning.id, user.uid);

            this.setState({ planning: planningFromApi });
        } catch(err) {
            console.log("Erreur lors de la récupération du menu : ", err);
            message.error("Une erreur s'est produite lors du chargement du menu.");
        }

        this.props.onLoaded();
    }

    initModal = (mealType, mealId = "", main = defaultValue, starter = defaultValue) => 
        this.setState({ 
            isModalVisible: true,
            formInitialStarterId: starter,
            formInitialMainId: main,
            formMealType: mealType,
            formMealId: mealId,
            formStarterId: starter,
            formMainId: main,
        })

    handleCancel = e => {
        e.preventDefault();
        this.setState({
            isModalVisible: false,
            formInitialStarterId: {...defaultValue},
            formInitialMainId: {...defaultValue},
            formMealType: "",
            formMealId: "",
            formStarterId: {...defaultValue},
            formMainId: {...defaultValue},
        });
    };

    handleOk = e => {
        e.preventDefault();
        
        const { form } = this.formRef.props;

        let {
            formInitialMainId,
            formInitialStarterId,
            formMealType,
            formMealId
        } = this.state;

        form.validateFields(async (err, values) => {
            if( !err ) {
                try {
                    console.log("Values : ",values);
                    this.setState({ isModalLoading: true, });
                    
                    let starterRecipe = null;
                    let mainRecipe = null;

                    let userId = this.props.location.state.user.uid;
                    let planningId = this.props.location.state.planning.id;
                    let mealGroup = formMealType === 'dinner' ? "dinners" : "lunches";

                    if( formMealId === "") { // it's a meal creation
                        // in case the user has filled a startedId value
                        // we check if the starter recipe exists
                        if( values.formStarterId !== "" && formInitialStarterId.value !== values.formStarterId ) {
                            let recipe = await recipes.getRecipeDetail(values.formStarterId);

                            starterRecipe = {
                                id: recipe.id,
                                imgUrl: recipe.imgUrl,
                                title: recipe.title
                            }
                        }

                        // in case the user has filled a new mainId value
                        // we check if the main recipe exists
                        // note: the main Id in the form cannot be empty
                        if( formInitialMainId.value !== values.formMainId ) {
                            let recipe = await recipes.getRecipeDetail(values.formMainId);

                            mainRecipe = {
                                id: recipe.id,
                                imgUrl: recipe.imgUrl,
                                title: recipe.title
                            }
                        }

                        // launch the creation request that returns the details of the new meal
                        let addedMeal = await plannings.doAddMealToPlanning(userId, planningId, formMealType, mainRecipe, starterRecipe );

                        // update the page with the newly added meal
                        let {
                            main,
                            mealId
                        } = addedMeal;

                        let updatedPlanning = {...this.state.planning};
                        let newMeal = {
                            id: mealId,
                            main: main,
                        }
                        if( typeof addedMeal.starter !== 'undefined' ) {
                            newMeal.starter = addedMeal.starter;
                        }
                        updatedPlanning[mealGroup].push(newMeal);

                        // hide the modal
                        this.setState({
                            planning: updatedPlanning,
                            isModalVisible: false,
                            isModalLoading: false,
                            formMealType: "",
                            formStarterId: {...defaultValue},
                            formMainId: {...defaultValue},
                        });
                    } else { // the meal Id is not empty so it means it is an update of an existing meal
                       
                        if( formInitialStarterId.value !== values.formStarterId ) {
                            console.log("Valeurs différentes : initiale = ", formInitialStarterId.value, " / saisie = ", values.formStarterId);
                            if( values.formStarterId !== "" ) {
                                let recipe = await recipes.getRecipeDetail(values.formStarterId);

                                starterRecipe = {
                                    id: recipe.id,
                                    imgUrl: recipe.imgUrl,
                                    title: recipe.title
                                }
                            } else {
                                starterRecipe = null; // this will delete the starter from the meal
                            }
                        } else {
                            console.log("Valeurs identiques : initiale = ", formInitialStarterId.value, " / saisie = ", values.formStarterId);
                            if( formInitialStarterId.value !== "" ) {
                                starterRecipe = {
                                    id: formInitialStarterId.value,
                                    imgUrl: formInitialStarterId.imgUrl,
                                    title: formInitialStarterId.title
                                }
                            }
                            // the starter recipe remains null if already empty
                        }

                        
                        if( formInitialMainId.value !== values.formMainId ) {
                            let recipe = await recipes.getRecipeDetail(values.formMainId);

                            mainRecipe = {
                                id: recipe.id,
                                imgUrl: recipe.imgUrl,
                                title: recipe.title
                            }
                        } else {
                            mainRecipe = {
                                id: formInitialMainId.value,
                                imgUrl: formInitialMainId.imgUrl,
                                title: formInitialMainId.title
                            }
                        }

                        console.log("Starter recipe : ",starterRecipe);
                        console.log("Main recipe : ", mainRecipe);

                        let updateRequest = {
                            userId: userId,
                            planningId: planningId,
                            mealId: formMealId,
                            mealType: formMealType,
                            main: mainRecipe,
                            starter: starterRecipe
                        };

                        console.log("Update Request : ", updateRequest);

                        let updateResult = await plannings.doUpdateMeal(updateRequest);
                        
                        console.log("Updated meal : ", updateResult);
                        
                        let updatedPlanning = {...this.state.planning};
                        // find the index of the recipe in the planning meal group (dinners or lunches)
                        let indexToUpdate = updatedPlanning[mealGroup].findIndex((element) => element.id === formMealId);

                        // update the recipe with the new values
                        updatedPlanning[mealGroup][indexToUpdate] = {
                            ...updatedPlanning[mealGroup][indexToUpdate],
                            main: updateResult.main,
                            starter: updateResult.starter
                        }

                        // hide the modal
                        this.setState({
                            planning: updatedPlanning,
                            isModalVisible: false,
                            isModalLoading: false,
                            formInitialStarterId: {...defaultValue},
                            formInitialMainId: {...defaultValue},
                            formMealType: "",
                            formMealId: "",
                            formStarterId: {...defaultValue},
                            formMainId: {...defaultValue},
                        });
                    }

                    
                } catch(err) {
                    console.log("Erreur lors de la création du repas : ", err);
                    message.error("Une erreur s'est produite lors de la création du repas.");
                    this.setState({
                        isModalLoading: false,
                    })
                }
            }
        });
    };

    handleChange = (changedFields) => {
        this.setState({
            ...changedFields
        });
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        const { planning } = this.state;

        return(
            !!planning && 
            <div id="admin-planning-detail">
                <PageHeader label={planning.label} />
                <Row gutter={16}>
                    <DinnersTable dinners={planning.dinners} onAction={this.initModal} />
                    <LunchesTable lunches={planning.lunches} onAction={this.initModal} />
                    <MealForm 
                        wrappedComponentRef={this.saveFormRef}
                        visible={this.state.isModalVisible}
                        loading={this.state.isModalLoading}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        onChange={this.handleChange}
                        starterId={this.state.formStarterId}
                        mainId={this.state.formMainId}
                    />
                </Row>
            </div>
        );
    }
}

const PlanningContent = templatePrivatePage(PlanningAdminContent, "Administration")

export default withAuthorization(condition)(PlanningContent)