import React from 'react'
import { Spin, Layout, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { FunnelHeader } from '../../../Content/Layout/Headers'

const { Content } = Layout

const { confirm } = Modal

const templatePrivateFunnelPage = (PageContent) => {
    class templatePrivateFunnelPageComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isPageLoading: false,
                cancelDestination: "",
                showModal: false,
                headerLabel: ''
            }
        
            this.pageLoading = this.pageLoading.bind(this);
            this.pageLoaded = this.pageLoaded.bind(this);
            this.setHeaderLabel = this.setHeaderLabel.bind(this);
            this.setCancel = this.setCancel.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
            this.trackPage = this.trackPage.bind(this);
        }

        componentDidMount() {
            // initialize the drift button. Only solution I imagined to solve the issue
            // of having the drift button working on every page of the app
            if( typeof window !== 'undefined' && window.drift ) {
                window.drift.on('ready', (api, payload) => {
                    api.widget.hide();

                    function forEachElement(selector, fn) {
                        var elements = document.querySelectorAll(selector);
                        for (var i = 0; i < elements.length; i++)
                            fn(elements[i], i);
                    }

                    let DRIFT_CHAT_SELECTOR = '.drift-open-chat';

                    function openSidebar(driftApi, event) {
                        event.preventDefault();
                        driftApi.sidebar.open();
                        return false;
                    }

                    let handleClick = openSidebar.bind(this, api);

                    forEachElement(DRIFT_CHAT_SELECTOR, function(el) {
                        el.addEventListener('click', handleClick);
                    });
                });
            }
        }

        pageLoading = () => this.setState({ isPageLoading: true, })
        pageLoaded = () => this.setState({ isPageLoading: false, })
        setCancel = (route) => this.setState({ cancelDestination: route, })
        handleCancel = () => {
            // we define the function here in order to have 'this' that matches with the component
            const handleOk = () => this.props.history.push(this.state.cancelDestination);

            confirm({
                title: 'Es-tu sûr.e de vouloir arrêter ?',
                content: 'Les choix que tu as pu faire jusqu\'à présent ne seront pas conservés',
                centered: true,
                okText: 'Quitter la sélection',
                okType: 'danger',
                cancelText: 'Rester',
                onOk() {
                    handleOk();
                },
                onCancel() {}
            })
        }

        setHeaderLabel = (text) => this.setState({ headerLabel: text, })

        trackPage = (user, page = null) => {
            if( typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
                
                let userTraits = {
                    name: user.firstName + ' ' + user.name,
                    email: user.email
                }

                window.analytics.identify(user.uid, userTraits);
                
                if( !!page ) {
                    if( typeof page.properties !== 'undefined') {
                        window.analytics.page(page.name, page.properties);
                    } else {
                        window.analytics.page(page.name);
                    }
                    
                }
            }
        }

        render() {
            return(
                <Spin spinning={this.state.isPageLoading}>
                    <Layout className="app-main funnel-page">
                        <FunnelHeader onCancel={this.handleCancel} label={this.state.headerLabel} />
                        <Content className="page-content">
                            <PageContent 
                                {...this.props}
                                onLoading={this.pageLoading}
                                onLoaded={this.pageLoaded}
                                setCancel={this.setCancel}
                                onCancel={this.handleCancel}
                                setHeaderLabel={this.setHeaderLabel}
                                trackPage={this.trackPage}
                            />
                        </Content>
                    </Layout>
                </Spin>
            );
        }
    }

    return withRouter(templatePrivateFunnelPageComponent);
}

export default templatePrivateFunnelPage