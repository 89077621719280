import React from 'react'
import templatePublicPage from '../Templates/PublicPage'
import { Row, Col, message } from 'antd';
import { SimpleLogo } from '../../Content/Logo/SimpleLogo'
import SigninForm from './SigninForm'
import { PageHeader } from '../../Content/Layout/Headers'
import { auth } from '../../../services/firebase'
// import * as routes from '../../../constants/routes'

const colResponsive = {
    xs: 22,
    sm: 16,
    md: 16,
    lg: 16,
    xl: 16,
    xxl: 16
}

class SigninContent extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleSubmit = (e) => {
        e.preventDefault();

        const { form } = this.form.props;

        form.validateFields(async (err, values) => {
            if( !err ) {
                try {
                    this.props.onLoading();

                    await auth.doSignInWithEmailAndPassword(values.login, values.password);
                    
                    this.props.onLoaded();
                    // this.props.history.push(routes.HOME);
                } catch(error) {
                    console.log(error);
                    this.props.onLoaded();
                    message.error("Une erreur s'est produite lors de l'authentification. Merci de bien vouloir réessayer.");
                }
            }
        })
    }

    render() {
        return(
            <Row
                id="signin-page"
                type="flex"
                justify="center"
                align="middle"
            >
                <Col {...colResponsive}>
                    <div className="page-content">
                        <SimpleLogo />
                        <PageHeader label="Ravi de te revoir !" />
                        <p>Qu'allons-nous préparer de bon ensemble cette semaine ?</p>
                        <SigninForm onSubmit={this.handleSubmit} wrappedComponentRef={(form) => this.form = form} />
                    </div>
                </Col>
            </Row>
        );
    }
}

const SignIn = templatePublicPage(SigninContent)

export default SignIn