import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import * as routes from '../../../constants/routes'
import { MenuToolbar, MainToolbar } from '../../Content/Layout/Toolbar'
import * as planningsApi from '../../../api/plannings'
import { Row, Col, message } from 'antd'
import Schedule from './Schedule'
import DayEdit from '../../Content/Plannings/DayEdit'
import { EmptyLunchesList, EmptyDinnersList } from '../../Content/Meals/Meals'
import MealCounter from '../../Content/Plannings/MealCounter'
import { createPlanningTracking } from '../../../tools/Segment'

const condition = auth => !!auth

class PlanningScheduleContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
        }
    }

    async componentDidMount() {
        this.props.setBackpage(routes.ORGA_HOME);


        this.props.onLoading();
        // retrieve the information about the planning
        // update the state with the new object    
        try {
            let planningFromApi = await planningsApi.getPlanningDetail(this.props.match.params.id);
            // console.log("Planning récupéré : ",planningFromApi);
            this.setState({
                planning: planningFromApi,
            },
                () => this.props.setPageTitle(this.state.planning.label)
            );

            // track the page and user
            let pageTracking = createPlanningTracking("Menu / calendrier", this.state.planning);

            this.props.trackPage(this.props.user, pageTracking);
        } catch(err) {
            console.log("Erreur lors de la récupération du planning", err);
            message.error("Une erreur s'est produite lors de la récupération de votre planning. Merci de bien vouloir réessayer.");
        }
        
        this.props.onLoaded();
    }

    handleDayChange = async (newDay) => {
        this.props.onLoading();

        try { 
            let updatedPlanning = await planningsApi.doChangeFirstDay(this.state.planning.id, newDay);

            if( typeof updatedPlanning !== 'undefined' && updatedPlanning ) {
                let planning = {...this.state.planning};
                
                if( !newDay ) {
                    delete planning.firstDay;
                    delete planning.startDate;
                } else {
                    planning.firstDay = updatedPlanning.firstDay;
                    planning.startDate = updatedPlanning.startDate;
                }

                this.setState({ planning: planning, });

                message.success("Planning mis à jour");
            }
        } catch(err) {
            message.error("Une erreur s'est produite lors de la mise à jour du menu.")
        }

        this.props.onLoaded();
    }

    handleMealSchedule = async (mealType, mealId, day) => {
        console.log("Meal : ", mealId," / Day : ", day, " / Meal type : ", mealType);
        this.props.onLoading();

        try {
            await planningsApi.doUpdateMeal({
                planningId: this.state.planning.id,
                mealId: mealId,
                mealType: mealType,
                day: day
            })

            // once the db is updated, we update the local planning object
            this.setState((prevState) => {
                let mealGroup = mealType === 'lunch' ? 'lunches' : 'dinners';

                let updatedMealIndex = prevState.planning[`${mealGroup}`].findIndex((meal) => meal.id === mealId);
                prevState.planning[`${mealGroup}`][updatedMealIndex].day = day;

                return {
                    planning: prevState.planning,
                }
            })

            this.props.onLoaded();

            message.success("Ton menu a bien été mis à jour.")
        } catch(err) {
            console.log("Erreur lors de la mise à jour : ", err);

            this.props.onLoaded();
            message.error("Une erreur s'est produite lors de la mise à jour du menu. Merci de bien vouloir réessayer.");
        }
    }

    handleMealUnschedule = (mealType, mealId) => async () => {
        // console.log("Unschedule Meal : ", mealId, " / Meal type : ", mealType);
        this.props.onLoading();

        try {
            await planningsApi.doUpdateMeal({
                planningId: this.state.planning.id,
                mealId: mealId,
                mealType: mealType,
                day: null,
            })

            // once the db is updated, we update the local planning object
            this.setState((prevState) => {
                let mealGroup = mealType === 'lunch' ? 'lunches' : 'dinners';

                let updatedMealIndex = prevState.planning[`${mealGroup}`].findIndex((meal) => meal.id === mealId);
                delete prevState.planning[`${mealGroup}`][updatedMealIndex].day;

                return {
                    planning: prevState.planning,
                }
            })

            this.props.onLoaded();

            message.success("Ton menu a bien été mis à jour.")
        } catch(err) {
            console.log("Erreur lors de la mise à jour : ", err);

            this.props.onLoaded();
            message.error("Une erreur s'est produite lors de la mise à jour du menu. Merci de bien vouloir réessayer.");
        }
    } 

    render() {
        let { planning } = this.state;
        // if the planning has not yet been fetched, display loading message
        if( !planning ) return <div>Chargement de la page...</div>
        
        // when the planning is loaded in state, we can render
        let {
            id,
            startDate,
            lunches,
            dinners
        } = planning;

        // will help display components for "empty" state
        let hasNoMeal = 
            (typeof lunches === 'undefined' || lunches.length === 0)
            && (typeof dinners === 'undefined' || dinners.length === 0);

        let hasFirstDay = typeof startDate !== 'undefined' && startDate !== null

        return(
            <div id="menu" className="planning-schedule">
                <MenuToolbar schedule planningId={id} />
                <MealCounter planning={planning} />
                {/* <PageHeader label={label} action={EditAction} /> */}
                {hasNoMeal && 

                <Row className="schedule-nomeal">
                    <Col>
                        <p>Ton menu ne contient aucun repas. Ajoute des repas avant de pouvoir gérer ton calendrier.</p>
                    </Col>
                    <EmptyDinnersList planningId={id} showTitle />
                    <EmptyLunchesList planningId={id} showTitle />
                </Row>

                }
                {!hasNoMeal && !hasFirstDay && 
                
                <Row>
                    <DayEdit day={startDate} onChange={this.handleDayChange} />
                </Row>
                
                }
                {!hasNoMeal && hasFirstDay && 
                
                <Schedule 
                    planning={planning} 
                    onMealSchedule={this.handleMealSchedule}
                    onMealUnschedule={this.handleMealUnschedule}
                />
                
                }
            </div>
        );
    }
}

const PlanningSchedule = templatePrivatePage(PlanningScheduleContent, "", null, MainToolbar)

export default withAuthorization(condition)(PlanningSchedule)
