import React from 'react'
import { RecipeOverallDuration } from '../RecipeTimes'
import RecipeDifficulty from '../RecipeDifficulty'

import { Link } from 'react-router-dom'
import * as routes from '../../../../constants/routes'

import './styles.css'

const RecipeTile = (props) => {
    let {
        id,
        difficulty,
        description,
        imgUrl,
        title
    } = props.recipe

    return(
        <div className="oc-recipe-tile">
            <Link to={routes.RECIPE_DETAIL(id)}>
                <div className="cover" style={{ backgroundImage: `url(${imgUrl})` }}>
                    <div className="additional-information">
                        <RecipeOverallDuration value={props.recipe.completeTime} />
                        <RecipeDifficulty value={difficulty} />
                    </div>
                </div>
            </Link>
            <div className="content">
                <div className="title">
                    {title}
                </div>
                {typeof description !== 'undefined' &&

                <div className="short-description">
                    {description}
                </div>
                
                }
                <Link className="category-link" to={routes.RECIPE_DETAIL(id)}>Voir la recette</Link>
            </div>
        </div>
    )
}

export default RecipeTile