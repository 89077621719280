import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { Row, Col, message } from 'antd'
import LinkButton from '../../Content/Buttons/LinkButton'
import { getPlannings } from '../../../api/plannings'
import PlanningList from '../../Content/Plannings/PlanningList'
import * as routes from '../../../constants/routes'
import { MainToolbar } from '../../Content/Layout/Toolbar'
import { PageHeader } from '../../Content/Layout/Headers'
import { ReactComponent as NoPlanningIllustration } from '../../../img/MobileIdeas.svg'

const condition = (auth) => !!auth

class OrgaHomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plannings: null,
        }
    }

    async componentDidMount() {
        // start loading animation
        this.props.onLoading();
        
        this.props.setBackpage(routes.HOME);
        // fetch data from the database : list of plannings for one user
        try {
            let plannings = await getPlannings();
            
            // filter only by displaying plannings that are in 'new' or 'to be validated' statuses
            // TODO : add an argument to the api call so that we only request planning with 
            // those two statuses
            plannings = plannings.filter(planning => planning.status === 'new' || planning.status === 'tovalidate');
            // sort the plannings by creation date descending
            plannings.sort((a, b) => b.createdAt - a.createdAt);

            // update state with the list of plannings, then launch tracking
            this.setState({
                plannings: plannings,
            }, () => {
                let page = {
                    name: 'Mes menus',
                }

                this.props.trackPage(this.props.user, page);
            });
        } catch(err) {
            message.error("Une erreur s'est produite lors du chargement des menus. Merci de recharger la page.")
        }
        // end the page loading
        this.props.onLoaded();
    }

    render() {
        if( !this.state.plannings ) {
            return(
                <div></div>
            )
        } else if( this.state.plannings.length === 0 ) {
            return(
                <div>
                    <PageHeader label="Aucun menu en cours" />
                    <Row>
                        <Col>
                            <div className="empty-planning">
                                <NoPlanningIllustration />
                                <p>
                                    Tu n'as pas encore créé de menu. Un menu est une liste de recettes sélectionnées pour ta semaine. 
                                    Cette sélection te permettra ensuite de faire les courses.
                                </p>
                                <LinkButton 
                                    to={routes.MENU_CREATE}
                                    type="primary"
                                >
                                    Créer mon premier menu
                                </LinkButton>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return(
                <div id="menus">
                    <Row>
                        <Col>
                            <PlanningList dataSource={this.state.plannings} />
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

const OrgaHome = templatePrivatePage(OrgaHomeContent, "Mes menus", null, MainToolbar)

export default withAuthorization(condition)(OrgaHome)