import React, { Component } from 'react'
import './App.css'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import * as routes from './constants/routes'
import * as pages from './components/Pages'

import { ConfigProvider } from 'antd'
import fr_FR from 'antd/lib/locale-provider/fr_FR'

import { withAuthentication } from './services/sessions'
import DriftWidget from './tools/DriftWidget'
import { SegmentSnippet } from './tools/Segment'

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={fr_FR}>
        <div className="App">
          <DriftWidget />
          <SegmentSnippet />
          <Router>
            <Switch>
              <Route exact path={routes.HOME} component={pages.Home} />
              <Route exact path={routes.ONBOARDING_STEP1} component={pages.OnboardingStep1} />
              <Route exact path={routes.ONBOARDING_STEP2} component={pages.OnboardingStep2} />
              <Route exact path={routes.ONBOARDING_STEP3} component={pages.OnboardingStep3} />
              <Route exact path={routes.SIGNIN} component={pages.Signin} />
              <Route exact path={routes.ORGA_HOME} component={pages.OrgaHome} />
              {/* <Route exact path={routes.SHOP_HOME} component={pages.ShopHome} />
              <Route exact path={routes.COOK_HOME} component={pages.CookHome} /> */}
              <Route exact path={routes.MENU_CREATE} component={pages.MenuCreation} />
              <Route exact path={routes.PLANNING_DETAIL(':id')} component={pages.PlanningDetail} />
              <Route exact path={routes.PLANNING_DINNERS(':id')} component={pages.PlanningDinners} />
              <Route exact path={routes.PLANNING_LUNCHES(':id')} component={pages.PlanningLunches} />
              <Route exact path={routes.PLANNING_SCHEDULE(':id')} component={pages.PlanningSchedule} />
              <Route exact path={routes.PLANNING_DINNERS_CHOICE(':id')} component={pages.PlanningDinnersChoice} />
              <Route exact path={routes.PLANNING_DINNERS_SUMMARY(':id')} component={pages.PlanningDinnersSummary} />
              <Route exact path={routes.PLANNING_LUNCHES_CHOICE(':id')} component={pages.PlanningLunchesChoice} />
              <Route exact path={routes.PLANNING_LUNCHES_SUMMARY(':id')} component={pages.PlanningLunchesSummary} />
              <Route exact path={routes.PLANNING_EDIT(':id')} component={pages.PlanningEdit} />
              <Route exact path={routes.RECIPE_MATCHER} component={pages.RecipeMatcher} />
              <Route exact path={routes.RECIPE_DETAIL(':id')} component={pages.RecipeDetail} />
              <Route exact path={routes.MEAL_DETAIL(':id')} component={pages.MealDetail} />
              <Route exact path={routes.ADMIN} component={pages.Admin} />
              <Route exact path={routes.USER_DETAIL(':id')} component={pages.UserDetail} />
              <Route exact path={routes.PLANNING_ADMIN(':id')} component={pages.PlanningAdmin} />
              <Route exact path={routes.USER_PROFILE} component={pages.UserProfile} />
            </Switch>
          </Router>
        </div>
      </ConfigProvider>
    );
  }
}

export default withAuthentication(App)
