import React from 'react'
import templatePrivateFunnelPage from '../Templates/PrivateFunnelPage'
import { withAuthorization } from '../../../services/sessions'
import { PageHeader } from '../../Content/Layout/Headers'
import { Toolbar } from '../../Content/Layout/Toolbar'
import { CancelButton } from '../../Content/Buttons/CancelButton'
import { Col, Button, message } from 'antd'
import * as routes from '../../../constants/routes'
import * as plannings from '../../../api/plannings'
import { responsive } from '../../../tools'
import RecipePreview from '../../Content/Recipes/RecipePreview'

const respButton = responsive(12)

const condition = (auth) => !!auth

class PlanningDinnersSummaryContent extends React.Component {
    componentDidMount() {
        this.props.setCancel(routes.PLANNING_DETAIL(this.props.match.params.id));

        let page = {
            name: 'Dîners / Validation'
        }
        this.props.trackPage(this.props.user, page);
    }

    handleCancel = (e) => {
        e.preventDefault();

        // back to the detail of the planning
        this.props.history.push(routes.PLANNING_DETAIL(this.props.match.params.id));
    }

    validateMeals = async (e) => {
        e.preventDefault();
        
        let {
            matched,
            guests
        } = this.props.location.state;

        let planningId = this.props.match.params.id;

        this.props.onLoading();

        try{
            // parse the matched recipes to add each one to the planning as dinners
            for( var i = 0 ; i < matched.length ; i++ ) {
                // retrieve the data we want to keep in octave db
                let main = {
                    id: matched[i].id,
                    imgUrl: matched[i].imgUrl,
                    title: matched[i].title,
                    difficulty: matched[i].difficulty,
                    completeTime: matched[i].completeTime,
                    description: matched[i].description,
                };

                let userId = this.props.uid;
    
                await plannings.doAddMealToPlanning(userId, planningId, "dinner", main, null, guests);
                // TODO: raise error if error 400 received
            }
            
        } catch(err) {
            message.error("Une erreur s'est produite lors de l'enregistrement de vos recettes.");
        }

        this.props.onLoaded();

        // back to the detail of the planning
        this.props.history.push(routes.PLANNING_DETAIL(this.props.match.params.id));
    }

    render() {
        return(
            <div className="meals-summary withToolbar">
                <PageHeader label="Mes dîners" />
                <p>Voici les recettes que tu as sélectionnées pour ton menu. Il ne te reste plus qu'à valider pour les ajouter définitivement.</p>
                <div>
                    {this.props.location.state.matched.map((recipe) => 
                    <RecipePreview clickable key={recipe.id} recipe={recipe} />
                    )}
                </div>
                <Toolbar className="funnel-toolbar">
                    <Col {...respButton}>
                        <div className="toolbar-action left-action">
                            <CancelButton onClick={this.props.onCancel} />
                        </div>
                    </Col>
                    <Col {...respButton}>
                        <div className="toolbar-action right-action">
                            <Button type="primary" onClick={this.validateMeals}>Ajouter au menu</Button>
                        </div>
                    </Col>
                </Toolbar>
            </div>
        );
    }
}

const PlanningDinnersSummary = templatePrivateFunnelPage(PlanningDinnersSummaryContent)

export default withAuthorization(condition)(PlanningDinnersSummary)