export const HOME = '/'
export const SIGNIN = '/signin'

export const ONBOARDING_STEP1 = '/welcome/step1'
export const ONBOARDING_STEP2 = '/welcome/step2'
export const ONBOARDING_STEP3 = '/welcome/step3'

export const ORGA_HOME = '/organize'
export const SHOP_HOME = '/shop'
export const COOK_HOME = '/cook'

export const MENU_CREATE = '/menu/create'

export const PLANNING_DETAIL = (planningId) => '/planning/' + planningId
export const PLANNING_SCHEDULE = (planningId) => '/planning/' + planningId + '/schedule'

export const PLANNING_DINNERS = (planningId) => '/planning/' + planningId + '/dinners'
export const PLANNING_DINNERS_CHOICE = (planningId) => '/planning/' + planningId + '/dinners/choice'
export const PLANNING_DINNERS_SUMMARY = (planningId) => '/planning/' + planningId + '/dinners/summary'

export const PLANNING_LUNCHES = (planningId) => '/planning/' + planningId + '/lunches'
export const PLANNING_LUNCHES_CHOICE = (planningId) => '/planning/' + planningId + '/lunches/choice'
export const PLANNING_LUNCHES_SUMMARY = (planningId) => '/planning/' + planningId + '/lunches/summary'

export const PLANNING_EDIT = (planningId) => '/planning/' + planningId + '/edit'

export const RECIPE_DETAIL = (recipeId) => '/recipe/' + recipeId
export const RECIPE_MATCHER = '/recipe/matcher'

export const MEAL_DETAIL = (mealId) => '/meal/' + mealId

export const ADMIN = '/admin'
export const USER_DETAIL = (userId) => '/admin/user/' + userId
export const PLANNING_ADMIN = (planningId) => '/admin/planning/' + planningId
export const USER_PROFILE = '/userprofile'