import React from 'react'
import { Icon } from 'antd'

import './styles.css'

// function that helps display cooking times in minutes (xx min) or hours & minutes (xxhyy)
// momentjs could not do it (or I didn't find the option to do it)
const timedisplay = (duration) => {
    if( duration < 60 ) {
        return duration + " min";
    } else {
        let hours = Math.floor(duration/60);
        let minutes = duration % 60;
        let minutesDisplay = "";

        if( minutes === 0) {
            minutesDisplay = ""
        } else if( minutes < 10 ) {
            minutesDisplay = "0"+minutes;
        } else {
            minutesDisplay=minutes.toString();
        }

        return hours + "h" + minutesDisplay ;
    }
}
    

const RecipeTimes = (props) => {
    let {
        preparationTime,
        cookingTime,
        restingTime,
    } = props.recipe;

    return (
        <div className="time">
            {!preparationTime || preparationTime === 0 ? null : 
                <div>
                    <span className="preparation-time">Préparation : {timedisplay(preparationTime)}</span>
                </div>
            }
            {!cookingTime || cookingTime === 0 ? null : 
                <div>
                    <span className="cooking-time">Cuisson : {timedisplay(cookingTime)}</span>
                </div>
            }
            {!restingTime || restingTime === 0 ? null : 
                <div>
                    <span className="resting-time">Repos : {timedisplay(restingTime)}</span>
                </div>
            }
        </div>
    )
}

const RecipeOverallDuration = (props) => {
    
    return(
        typeof props.value !== 'undefined' &&
        
        <div className="oc-overall-duration">
            <Icon className="icon" type="clock-circle" /><span>{props.value} min.</span>
        </div>

    )
}

export {
    RecipeTimes,
    RecipeOverallDuration
}
