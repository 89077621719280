import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { auth } from '../../../services/firebase'
import { withAuthorization } from '../../../services/sessions'
import { Row, Col, message } from 'antd'
import { PageHeader } from '../../Content/Layout/Headers'
import { MainToolbar } from '../../Content/Layout/Toolbar'
import SignOutButton from '../../Content/Buttons/SignOutButton'

const condition = auth => !!auth

class UserProfileContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userTokenId: '',
            uid: '',
            email: '',
            firstName: '',
            name: '',
        }
    }

    async componentDidMount() {
        this.props.onLoading();
        
        try {
            const userTokenId = await auth.getUserTokenId();
            // const userId = auth.getUserId();
            const user = this.props.user;

            this.setState({
                userTokenId: userTokenId,
                ...user
            }, () => {
                let page = {
                    name: 'Profil utilisateur',
                    properties: {
                        userId: this.state.uid
                    }
                }

                this.props.trackPage(this.props.user, page);
            });
        } catch(err) {
            message.error("Une erreur s'est produite lors de la récupération des informations de ton compte.");
        }

        this.props.onLoaded();
        
    }

    render() {
        return(
            <div id="user-profile">
                <PageHeader label={this.state.firstName + ' ' + this.state.name} />
                <Row>
                    <Col>
                        <div>
                            {this.state.email}
                        </div>
                    </Col>
                    <Col>
                        <div>
                            {process.env.NODE_ENV !== 'production' ?
                                <div style={{fontSize:'8px'}}>
                                    <div>
                                        <h3>User token Id : </h3>
                                        <div>{this.state.userTokenId}</div>
                                    </div>
                                    <div>
                                        <h3>User Id : </h3>
                                        <div>{this.state.uid}</div>
                                    </div>
                                </div>
                            : null}
                            <SignOutButton />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const UserProfile = templatePrivatePage(UserProfileContent, "Mon profil", null, MainToolbar)

export default withAuthorization(condition)(UserProfile)