import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { LinkButton } from '../Buttons/LinkButton'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'

const PlanningTable = (props) => {
    const columns = [
        {
            title: 'Nom',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => <Link to={{
                pathname: routes.PLANNING_ADMIN(record.id),
                state: {
                    planning: record,
                    user: props.user
                }
            }}>{text}</Link>
        }, {
            title: 'Date de création',
            dataIndex: 'creation-date',
            render: (text, record) => moment(record.createdAt).format("DD/MM/YYYY HH:mm")
        }, {
            title: 'Nb de dîners',
            dataIndex: 'dinnersNb',
            render: (text, record) => typeof record.dinners !== 'undefined' ? Object.keys(record.dinners).length : 0
        }, {
            title: 'Nb de déjeuners',
            dataIndex: 'lunchesNb',
            render: (text, record) => typeof record.lunches !== 'undefined' ? Object.keys(record.lunches).length : 0
        }, {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => 
                <LinkButton 
                    to={{
                        pathname: routes.PLANNING_ADMIN(record.id),
                        state: {
                            planning: record,
                            user: props.user
                        }
                    }}
                    type="primary"
                    shape="circle"
                    icon="edit"
                />
        }
    ];

    return(
        <Table 
            rowKey={(record) => record.id}
            dataSource={props.plannings}
            columns={columns}
        />
    );
}

export default PlanningTable