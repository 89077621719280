import React from 'react'
import { Row, Col, Button, Icon } from 'antd'
import { responsive } from '../../../tools'


const PageHeader = (props) => {
    let hasAction = !!props.action;
    let maxSpan = 24;
    // manage display depending on the definition of an action
    const titleResponsive = responsive(maxSpan - 2*hasAction);
    const actionResponsive = responsive(2*hasAction);

    return(
        <Row className="content-header">
            <Col className="content-header-title" {...titleResponsive}>
                <h1>{props.label}</h1>
            </Col>
            {hasAction &&
                <Col className="content-header-action" {...actionResponsive}>
                    {props.action}
                </Col>
            }
        </Row>
    )
}

class EditablePageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
        }

        this.nodeRef = React.createRef();

        this.toggleEdit = this.toggleEdit.bind(this);
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isValueChanged = this.isValueChanged.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    toggleEdit = (e) => {
        e.preventDefault();

        if ( this.state.isEditing ) {
            this.cancel();
        } else {
            this.edit();
        }
    }

    edit = () => {
        this.setState({
            isEditing: true
        }, () => {
            this.nodeRef.current.focus();
        });
    }
    
    save = () => {
        this.setState({
            isEditing: false
        }, () => {
            if ( this.isValueChanged()) {
                this.props.onTitleChange(this.nodeRef.current.textContent);
            }
        });
    }

    cancel = () => {
        this.setState({
            isEditing: false
        });
    }

    isValueChanged = () => {
        return this.props.value !== this.nodeRef.current.textContent
    }

    handleKeyDown = (e) => {
        const { key } = e;
        switch (key) {
            case 'Enter':
                this.save();
                break;
            case 'Escape':
                this.cancel();
                break;
            default:
                return;
        }
    }

    render() {
        let { isEditing } = this.state;

        const titleResponsive = responsive(21);
        const actionResponsive = responsive(3)

        return(
            <Row className="content-header editable">
                <Col className="content-header-title" {...titleResponsive}>
                    <h1  
                        className={isEditing ? 'editing' : ''}
                        contentEditable={isEditing}
                        suppressContentEditableWarning={true} // to prevent React warnings in the console
                        ref={this.nodeRef}
                        onClick={!isEditing ? this.toggleEdit : undefined}
                        onKeyDown={this.handleKeyDown}
                        onBlur={this.save}
                    >
                        {this.props.value}
                    </h1>
                </Col>
                <Col className="content-header-action" {...actionResponsive}>
                    {!isEditing && 
                    
                    <Icon type="edit" className="edit-action" onClick={this.toggleEdit} />
                    
                    }
                    {isEditing &&
                    
                    <Icon type="check" className="edit-action" onClick={this.save} />
                    
                    }
                </Col>
            </Row>
        );
    }
}

const FunnelHeader = (props) => {
    const respButton = responsive(2);

    return(
        <Row 
            className="funnel-header"
            type="flex"
            justify="end"
        >
            { !!props.label && props.label.length > 0 &&
            <Col className="label-container" {...responsive(22)}>
                <span>{props.label}</span>
            </Col>
            }
            <Col className="close-button-container" {...respButton}>
                <Button 
                    className="close-funnel--button"
                    shape="circle"
                    icon="close"
                    onClick={props.onCancel}
                />
            </Col>
        </Row>
    );
}

export {
    PageHeader,
    FunnelHeader,
    EditablePageHeader
}