import React from 'react'
import { Button } from 'antd'
import { withRouter } from 'react-router-dom'

const CancelButton = (props) =>
    <Button className="button__cancel" type="danger" onClick={props.onClick}>Annuler</Button>

const BackButtonComponent = (props) =>
    <Button className="button__cancel" type="danger" onClick={props.history.goBack}>Retour</Button>

const BackButton = withRouter(BackButtonComponent)

export {
    CancelButton,
    BackButton
} 