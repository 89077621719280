import React from 'react'
import { Form, Button, Input } from 'antd'

const INITIAL_STATE = {
    login: '',
    password: '',
}

class SigninFormComponent extends React.Component {
    render() {
        const { getFieldDecorator } = this.props.form;

        return(
            <Form className="octave-form" onSubmit={this.props.onSubmit}>
                <Form.Item>
                    {getFieldDecorator('login', {
                        rules: [
                            { type: 'email', message: 'Merci de saisir une adresse email valide'},
                            { required: true, message: "Merci de renseigner votre identifiant."}
                        ],
                        initialValue: INITIAL_STATE.login,
                    })(
                        <Input placeholder="Adresse email" autoComplete="username" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: "Merci de renseigner votre mot de passe."}],
                        initialValue: INITIAL_STATE.password,

                    })(
                        <Input type="password" placeholder="Mot de passe" autoComplete="current-password" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button className="form-input-button" type="primary" htmlType="submit">Connexion</Button>
                </Form.Item>

            </Form>
        );
    }
}

const SigninForm = Form.create({ name: 'sign_in_form' })(SigninFormComponent)

export default SigninForm