import React from 'react'
import { Button, Menu, Dropdown, Icon } from 'antd'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import SignOutButton from './SignOutButton'

const accountMenu = (
    <Menu>
        <Menu.Item><Link to={routes.USER_PROFILE}><Icon type="idcard" /> Mon compte</Link></Menu.Item>
        <Menu.Divider />
        <Menu.Item><SignOutButton /></Menu.Item>
    </Menu>
)

const AccountButton = (props) => 
    <Dropdown
        overlay={accountMenu}
        trigger={['click']}
    >
        <Button type="primary" icon="user" />
    </Dropdown>

export default AccountButton