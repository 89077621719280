import React from 'react'

import { withRouter } from 'react-router-dom'
import { Card, Icon, Modal } from 'antd'
import { dayInFrench } from '../../../tools'

const { confirm } = Modal

const MealTile = (props) => {
    let {
        id,
        starter,
        main,
        day,
        guests
    } = props.meal;

    let hasStarter = typeof starter !== 'undefined' && starter !== null;
    let hasMain = typeof main !== 'undefined' && main !== null;
    let displayDay = typeof day !== 'undefined' && !props.hideDayTag;
    let displayGuests = typeof guests === 'object' && Object.keys(guests).length > 0;

    const AdditionalInformation = (props) =>
        (displayDay || displayGuests) && 
        <div className="meal-settings">
        {displayDay &&
            
            <DayTag day={day} />
        
        }
        {displayGuests &&

            <div className="guests"><Icon type="smile" theme="twoTone" twoToneColor="#008DD5"/> {Object.keys(guests).length}</div>

        }
        </div>

    const goToMealDetail = () => props.loadMeal(id)
    const deleteMeal = () => confirm({
        title: "Suppression du repas",
        content: "Es-tu sur.e de vouloir supprimer ce repas ?",
        okText: "Supprimer",
        okType: "danger",
        cancelText: "Annuler",
        onOk() {
            props.onMealDeleted(id);
        }
    })

    const actions = [
        <Icon type="edit" theme="filled" onClick={goToMealDetail} />,
        <Icon className="critical" type="delete" theme="filled" onClick={deleteMeal} />
    ]

    if( !hasMain ) {
        return(<div>Repas bizarre...</div>)
    }
    
    const cover = 
        !hasStarter
        ?
            <div 
                className="cover" 
                style={{ backgroundImage: `url(${main.imgUrl})`, }}
                onClick={() => props.loadMeal(id)}
            >
                <AdditionalInformation />
            </div>
        :
            <div 
                className="cover"
                onClick={() => props.loadMeal(id)}
            >
                <div className="starter" style={{ backgroundImage: `url(${starter.imgUrl})`, }} />
                <div className="main" style={{ backgroundImage: `url(${main.imgUrl})`, }} />
                <AdditionalInformation />
            </div>
    
    return(
        <Card 
            className="meal-card" 
            key={id}
            hoverable
            cover={cover}
            size="small"
            actions={actions}
        >
            <div onClick={() => props.loadMeal(id)}>
                {hasStarter && <span className="meal-title starter-title">{starter.title}</span>}
                <span className="meal-title main-title">{main.title}</span>
            </div>
        </Card>
    );
}

const DayTag = (props) => 
    !props.day 
        ? null
        : <div className="tag-day"><Icon type="calendar" theme="twoTone" twoToneColor="#008DD5"/> {dayInFrench(props.day)}</div>

export default withRouter(MealTile)