import apiConfig from './apiConfig'
import { auth } from '../services/firebase'

const apiURI = apiConfig.endpoint + apiConfig.version
const mealsURI = apiURI + '/meals'

// function that fetches the detail of one meal based on its id
export const getMealDetail = async (id) => {
    try {
        const idToken = await auth.getUserTokenId();

        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };
        let mealDetailURI = mealsURI + '/' + id;

        // send request to the API
        // expected success status is 200
        let response = await fetch(mealDetailURI, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        return data;

    } catch(err) {
        console.log(err);
    }
}