import React from 'react'
import LinkButton from './LinkButton'
import * as routes from '../../../constants/routes'

const AddPlanningButton = (props) => 
    <LinkButton
        to={routes.MENU_CREATE}
        className="button-add" 
        type="primary" 
        shape="circle" 
        icon="plus"
    />

/* <Button
        className="button-add" 
        type="primary" 
        shape="circle" 
        icon="plus" 
    /> */

export default AddPlanningButton