import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import { PageHeader } from '../../Content/Layout/Headers'
import { getMealDetail } from '../../../api/meals'
import { message, Row, Col } from 'antd'
import * as tools from '../../../tools'
import { MainToolbar } from '../../Content/Layout/Toolbar'

const condition = auth => !!auth

const recipeResponsive = tools.responsive(24,24,12,8,8,8);
const titleResponsive = tools.responsive(24);
const imgResponsive = tools.responsive(8,8,8,24,24,24);
const descResponsive = tools.responsive(16,16,16,24,24,24);

class MealDetailContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meal: null,
        }
    }

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message

        this.props.onLoading();
        
        // retrieve the information about the planning
        // update the state with the new object    
        try {
            console.log("Id du repas : ", this.props.match.params.id);
            let mealFromApi = await getMealDetail(this.props.match.params.id);

            this.setState({
                meal: mealFromApi,
            }, () => {
                let pageTitle =
                    typeof this.state.meal.planning !== 'undefined'
                    ? this.state.meal.planning.label
                    : "Mon repas";
                
                this.props.setPageTitle(pageTitle);

                // Code that helps manage the legacy meals : at the beginning, planning data weren't
                // added to the meal. But it is now the case. This test can be deleted after launch + few weeks
                if( typeof this.state.meal.planning !== 'undefined' ) {
                    this.props.setBackpage(routes.PLANNING_DETAIL(this.state.meal.planning.id))
                }

                let page = {
                    name: 'Repas / détail',
                    properties: {
                        mealId: this.state.meal.id
                    }
                }

                this.props.trackPage(this.props.user, page);
            });

        } catch(err) {
            console.log("Erreur lors de la récupération du repas : ", err);
            message.error("Une erreur s'est produite lors de la récupération de votre repas. Merci de bien vouloir réessayer.")
        }
        this.props.onLoaded();
    }

    render() {
        if( !this.state.meal ) {
            return <div>Chargement du repas</div>;
        }

        const { starter, main, side } = this.state.meal;
        // let { id } = planning;

        const hasStarter = typeof starter !== 'undefined' && starter !== null;
        const hasMain = typeof main !== 'undefined' && main !== null;
        const hasSide = typeof side !== 'undefined' && side !== null;

        return(
            <div className="meal">
                <PageHeader label="Ton repas" />
                {/* <MenuToolbar list planningId={id} /> */}
                <Row>
                    {hasStarter &&
                        <Col {...recipeResponsive}>
                            <Row className="recipe-description" gutter={16}>
                                <Col {...titleResponsive}>
                                    <h3>Entrée</h3>
                                </Col>
                                <Col {...imgResponsive}>
                                    <img src={starter.imgUrl} alt="" />
                                </Col>
                                <Col {...descResponsive}>
                                    <h4>{starter.title}</h4>
                                    {/* <p>{starter.description}</p> */}
                                    <Link to={routes.RECIPE_DETAIL(starter.id)}>Voir le détail de la recette</Link>
                                </Col>        
                            </Row>
                        </Col>
                    }
                    {hasMain &&
                        <Col {...recipeResponsive}>
                            <Row className="recipe-description" gutter={16}>
                                <Col {...titleResponsive}>
                                    <h3>Plat de résistance</h3>
                                </Col>
                                <Col {...imgResponsive}>
                                    <img src={main.imgUrl} alt="" />
                                </Col>
                                <Col {...descResponsive}>
                                    <h4>{main.title}</h4>
                                    <Link to={routes.RECIPE_DETAIL(main.id)}>Voir le détail de la recette</Link>
                                </Col>
                            </Row>
                        </Col>
                    }
                    {hasSide &&
                        <Col {...recipeResponsive}>
                            <Row className="recipe-description" gutter={16}>
                                <Col {...titleResponsive}>
                                    <h2>Accompagnement</h2>
                                </Col>
                                <Col {...imgResponsive}>
                                    <img src={side.imgUrl} alt="" />
                                </Col>
                                <Col {...descResponsive}>
                                    <span>{side.title}</span>
                                    <Link to={routes.RECIPE_DETAIL(side.id)}>Voir le détail de la recette</Link>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </div>
            
        )
    }
}

const MealDetail = templatePrivatePage(MealDetailContent, "", null, MainToolbar)

export default withAuthorization(condition)(MealDetail)