import React from 'react'
import templatePrivateFunnelPage from '../Templates/PrivateFunnelPage'
import { withAuthorization } from '../../../services/sessions'
import matcher from '../../../services/match-engine'
import { Col, message } from 'antd'
import { RecipeCard, MatchedRecipeCard, DiscardedRecipeCard } from '../../Content/Recipes/RecipeCard'
import { Toolbar } from '../../Content/Layout/Toolbar';
import { MatchButton, DiscardButton, RewindButton, NextButton } from '../../Content/Buttons/RecipeMatcherButtons'
import * as routes from '../../../constants/routes'
import { responsive } from '../../../tools/index'

const condition = auth => !!auth

class RecipeMatcherContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planningId: this.props.location.state.planningId,
            recipeType: this.props.location.state.recipeType,
            mealType: this.props.location.state.mealType,
            total: this.props.location.state.total,
            matched: this.props.location.state.matched,
            discarded: this.props.location.state.discarded,
            guests: this.props.location.state.guests,
            recipe: null,
        }

        this.getUserDislikes = this.getUserDislikes.bind(this);
        this.match = this.match.bind(this);
        this.next = this.next.bind(this);
        this.rewind = this.rewind.bind(this);
        this.discard = this.discard.bind(this);

    }

    async componentDidMount() {
        this.props.onLoading();

        try {
            // fetch user's tastes in order to feed the match engine
            let dislikes = this.getUserDislikes();

            // request a recipe from delizioso with the type from mealType
            let recipe = await matcher({ type: this.state.recipeType, dislikes: dislikes, });

            // display the recipe to user by updating the state
            this.setState({ recipe: recipe, },
                () => {
                    // define the back page in case of cancellation
                    this.props.setCancel(routes.PLANNING_DETAIL(this.props.location.state.planningId));
                    
                    // define the label for the page header
                    this.setHeaderLabel();

                    // track the page
                    let mealTypeLabel = this.state.mealType === 'dinner' ? 'Dîners' : 'Déjeuners'
                    let page = {
                        name: `${mealTypeLabel} / Matcher`,
                        properties: {
                            recipeId: this.state.recipe.id,
                            recipeLabel: this.state.recipe.title,
                        }
                    };

                    this.props.trackPage(this.props.user, page)
                }
            );

        } catch(err) {
            console.log("Une erreur s'est produite lors du chargement de la recette :", err);
            message.error("Une erreur s'est produite lors du chargement de la recette.");
        }
        
        this.props.onLoaded();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        // we implement this method in order to change the render depending on the user's choice
        if( !this.state.recipe && prevState.recipe !== this.state.recipe ) {
            this.props.onLoading();

            try {
                // create the exclusion if needed i.e if matched or discarded aren't empty
                let exclusionList = [];
                let {
                    matched,
                    discarded
                } = this.state;

                // create the exclusion list by combining discarded and matched recipes
                if( discarded.length > 0 ) {
                    exclusionList = [...discarded];
                }
                if ( matched.length > 0 ) {
                    exclusionList = [...exclusionList, ...matched.map((m) => m.id)]
                }

                let dislikes = this.getUserDislikes();

                // request a recipe from delizioso with the type from mealType and the exclusion list
                // and the user's characteristics
                let recipe = await matcher({ type: "main", exclusion: exclusionList, dislikes: dislikes, });

                let newState = {...this.state}
                newState.recipe = recipe;
                
                this.setState(newState, () => {
                    this.setHeaderLabel();
                    
                    // track the page
                    let mealTypeLabel = this.state.mealType === 'dinner' ? 'Dîners' : 'Déjeuners'
                    let page = {
                        name: `${mealTypeLabel} / Matcher`,
                        properties: {
                            recipeId: this.state.recipe.id,
                            recipeLabel: this.state.recipe.title,
                        }
                    };

                    this.props.trackPage(this.props.user, page)
                });
            // display the recipe to user by updating the state
            } catch(err) {
                console.log("Une erreur s'est produite lors du chargement de la recette :", err);
                message.error("Une erreur s'est produite lors du chargement de la recette.");
            }
            
            this.props.onLoaded();
        }
    }

    setHeaderLabel = () => this.props.setHeaderLabel(`Recettes choisies : ${this.state.matched.length} / ${this.state.total}`)

    getUserDislikes = () => {
        if( typeof this.props.user.tastes !== 'undefined' 
            && typeof this.props.user.tastes.dislikes !== 'undefined' ) {
            return this.props.user.tastes.dislikes;
        } else {
            return {};
        }
    }

    match = (e) => {
        e.preventDefault();

        // from here you decide what type of data are stored in a recipe for one meal
        // must also be added to api request in summary pages (dinners and lunches)
        let matchedRecipe = {
            id: this.state.recipe.id,
            title: this.state.recipe.title,
            imgUrl: this.state.recipe.imgUrl,
            difficulty: this.state.recipe.difficulty,
            completeTime: this.state.recipe.cookingTime + this.state.recipe.preparationTime + this.state.recipe.restingTime,
            description: this.state.recipe.description
        }

        let matched = [...this.state.matched, matchedRecipe];

        this.setState({ matched: matched, }, this.setHeaderLabel);
    }

    discard = (e) => {
        e.preventDefault();

        let discarded = [...this.state.discarded, this.state.recipe.id];

        this.setState({ discarded: discarded, });
    }

    rewind = (e) => {
        e.preventDefault();
        let matched = [...this.state.matched];
        let discarded = [...this.state.discarded];

        // if( matched.includes(this.state.recipe.id) ) {
        //     matched.pop();
        // } 
        if( matched.findIndex((element) => element.id === this.state.recipe.id) >= 0 ) {
            matched.pop();
        } 

        if( discarded.includes(this.state.recipe.id) ) {
            discarded.pop();
        }

        this.setState({
            matched: matched,
            discarded: discarded,
        }, this.setHeaderLabel)
    }

    next = (e) => {
        e.preventDefault();

        let {
            total,
            recipeType,
            mealType,
            matched,
            discarded,
            planningId,
            guests
        } = this.state;

        if( total !== matched.length ) {
            // we don't have all the recipes the user needs, we reload the matcher by 
            // resetting the recipe in the component state
            this.setState({ recipe: null });
        } else {
            // we have all the recipes the user needed, let's move to the summary
            let nextState = {
                total: total,
                recipeType: recipeType,
                mealType: mealType,
                matched: matched,
                guests: guests,
                discarded: discarded,
            }

            // next step depends on the type of meal
            let nextRoute = 
                mealType === 'lunch' 
                ? routes.PLANNING_LUNCHES_SUMMARY(planningId)
                : routes.PLANNING_DINNERS_SUMMARY(planningId);
            
            this.props.history.push(nextRoute, nextState);
        }
    }

    render() {
        let {
            matched,
            discarded,
            recipe,
            total
        } = this.state;

        const remainder = total - matched.length;

        if( !recipe || Object.keys(recipe).length === 0 ) {
            return(
                <div>Chargement en cours</div>
            );
        } else if( (matched.findIndex((element) => element.id === this.state.recipe.id) < 0) && !discarded.includes(recipe.id) ) {
            // check if matched and discarded are not null (if both are null, then we are at the beginning of the funnel)
            // then check if the current recipe is not in matched array nor in discarded array
            return(
                <div className="recipe-matcher-content withToolbar">
                    <RecipeCard recipe={this.state.recipe} />
                    <Toolbar className="funnel-toolbar">
                        <Col>
                            <DiscardButton onClick={this.discard} />
                        </Col>
                        <Col>
                            <MatchButton onClick={this.match} />
                        </Col>
                    </Toolbar>
                </div>
            );
        } else if( matched.findIndex((element) => element.id === this.state.recipe.id) >= 0 ) {
            return(
                <div className="recipe-matcher-content withToolbar">
                    <MatchedRecipeCard recipe={this.state.recipe} remainder={remainder} />
                    <Toolbar className="funnel-toolbar">
                        <Col {...responsive(12)}>
                            <RewindButton onClick={this.rewind} />
                        </Col>
                        <Col {...responsive(12)}>
                            <NextButton onClick={this.next} />
                        </Col>
                    </Toolbar>
                </div>
            );
        } else if( discarded.includes(recipe.id) ) {
            return(
                <div className="recipe-matcher-content withToolbar">
                    <DiscardedRecipeCard recipe={this.state.recipe} remainder={remainder} />
                    <Toolbar className="funnel-toolbar">
                        <Col {...responsive(12)}>
                            <RewindButton onClick={this.rewind} />
                        </Col>
                        <Col {...responsive(12)}>
                            <NextButton onClick={this.next} />
                        </Col>
                    </Toolbar>
                </div>
            );
        }
    }
}

const RecipeMatcher = templatePrivateFunnelPage(RecipeMatcherContent, "Organiser mes repas")

export default withAuthorization(condition)(RecipeMatcher)
