import React from 'react'
import { Spin, Layout } from 'antd'
import { DesktopMenu } from '../../../Content/Layout/OctaveMenu'
import OctaveHeader from '../../../Content/Layout/OctaveHeader'
import { withRouter } from 'react-router-dom'
import MealModal from '../../../Content/Meals/MealModal'

const { Header, Sider, Content, Footer } = Layout

const templatePrivatePage = (PageContent, pageTitle = "", PageHeader = null, PageFooter = null) => {
    class templatePrivatePageComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isDrawerVisible: false,
                isPageLoading: false,
                backpage: null,
                pageTitle: "",
                displayMeal: false,
                mealId: null,
                displayRecipe: false,
                recipeId: null
            }
        
            this.onDrawerClose = this.onDrawerClose.bind(this);
            this.toggleDrawer = this.toggleDrawer.bind(this);
            this.pageLoading = this.pageLoading.bind(this);
            this.pageLoaded = this.pageLoaded.bind(this);
            this.setBackpage = this.setBackpage.bind(this);
            this.trackPage = this.trackPage.bind(this);
            this.loadMeal = this.loadMeal.bind(this);
            this.hideMeal = this.hideMeal.bind(this);
        }

        componentDidMount() {
            // initialize the drift button. Only solution I imagined to solve the issue
            // of having the drift button working on every page of the app
            if( typeof window !== 'undefined' && window.drift ) {
                window.drift.on('ready', (api, payload) => {
                    api.widget.hide();

                    function forEachElement(selector, fn) {
                        var elements = document.querySelectorAll(selector);
                        for (var i = 0; i < elements.length; i++)
                            fn(elements[i], i);
                    }

                    let DRIFT_CHAT_SELECTOR = '.drift-open-chat';

                    function openSidebar(driftApi, event) {
                        event.preventDefault();
                        driftApi.sidebar.open();
                        return false;
                    }

                    let handleClick = openSidebar.bind(this, api);

                    forEachElement(DRIFT_CHAT_SELECTOR, function(el) {
                        el.addEventListener('click', handleClick);
                    });
                });
            }
        }

        onDrawerClose = () => this.setState({ isDrawerVisible: false, })
    
        toggleDrawer = () => 
            this.setState((prevState) => (
                { isDrawerVisible: !prevState.isDrawerVisible, }
            ))
    
        pageLoading = () => this.setState({ isPageLoading: true, })
        pageLoaded = () => this.setState({ isPageLoading: false, })
        setBackpage = (route) => this.setState({ backpage: route, })
        setPageTitle = (title) => this.setState({ pageTitle: title, })
        trackPage = (user, page = null) => {
            if( typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
                
                if( !!user  ) {
                    let userTraits = {
                        name: user.firstName + ' ' + user.name,
                        email: user.email
                    }
    
                    window.analytics.identify(user.uid, userTraits);
                }
                
                if( !!page ) {
                    if( typeof page.properties !== 'undefined') {
                        window.analytics.page(page.name, page.properties);
                    } else {
                        window.analytics.page(page.name);
                    }
                }
            }
        }

        loadMeal = (mealId) => this.setState({ displayMeal: true, mealId: mealId })
        hideMeal = () => this.setState({ displayMeal: false, mealId: null })

        render() {
            return(
                <Spin spinning={this.state.isPageLoading}>
                    <MealModal 
                        mealId={this.state.mealId}
                        displayMeal={this.state.displayMeal}
                        handleClose={this.hideMeal}
                        trackPage={this.trackPage}
                    />
                    <Layout className="app-main private-page">
                        <Header className="app-header">
                            <OctaveHeader
                                title={ pageTitle.length === 0 ? this.state.pageTitle : pageTitle }
                                // onToggle={this.toggleDrawer}
                                back={this.state.backpage}
                            />
                        </Header>
                        <Layout className="page">
                            <Sider 
                                breakpoint="md"
                                collapsedWidth={0}
                                trigger={null}
                            >
                                <DesktopMenu />
                            </Sider>
                            {!!PageHeader &&

                            <Header className="page-header">
                                <PageHeader />
                            </Header>

                            }
                            <Content className="page-content">
                                {/* <Drawer
                                    className="mobile-drawer"
                                    visible={this.state.isDrawerVisible}
                                    placement="left"
                                    onClose={this.onDrawerClose}
                                >
                                    <MobileMenu />
                                </Drawer> */}
                                <PageContent 
                                    {...this.props}
                                    onLoading={this.pageLoading}
                                    onLoaded={this.pageLoaded}
                                    setBackpage={this.setBackpage}
                                    setPageTitle={this.setPageTitle}
                                    trackPage={this.trackPage}
                                    loadMeal={this.loadMeal}
                                />
                            </Content>
                        </Layout>
                        {!!PageFooter &&

                        <Footer className="page-footer">
                            <PageFooter />
                        </Footer>

                        }
                    </Layout>
                </Spin>
            );
        }
    }

    return withRouter(templatePrivatePageComponent);
}

export default templatePrivatePage