import React from 'react'
import { Row, Col, Button, Icon, Modal, message } from 'antd'
import * as tools from '../../../tools'
import RecipePreview from '../../Content/Recipes/RecipePreview'
import * as routes from '../../../constants/routes'
import { Link } from 'react-router-dom'

const toolbarResp = tools.responsive(12,12,12,12,0,0)

const ScheduledMeal = (props) => 
    <div className="scheduled-meal">
        <div className="action" onClick={props.onUnpin}>
            <Icon type="close" />
        </div>
        {typeof props.meal.starter !== 'undefined' &&
        <RecipePreview clickable recipe={props.meal.starter} mealId={props.meal.id} />
        }
        {typeof props.meal.main !== 'undefined' &&
        <RecipePreview clickable recipe={props.meal.main} mealId={props.meal.id} />
        }
    </div>

const SchedulableMeal = (props) => 
    <div className="schedulable-meal" onClick={props.onPin}>
        <div className="action">
            <Icon type="pushpin" theme="filled" />
        </div>
        {typeof props.meal.starter !== 'undefined' &&
        <RecipePreview recipe={props.meal.starter} mealId={props.meal.id} />
        }
        {typeof props.meal.main !== 'undefined' &&
        <RecipePreview recipe={props.meal.main} mealId={props.meal.id} />
        }
    </div>

const ScheduleLunches = (props) => {
    let {
        lunch,
        display,
        day
    } = props.mealDay;

    let {
        lunches,
        id
    } = props.planning;

    let unscheduledLunches = [];
    lunches.forEach((meal) => { if (!tools.isMealScheduled(meal)) unscheduledLunches.push(meal) });

    return(
        <Col {...display}>
            <div className="schedule-day">
                <h2>{tools.capitalizedDayInFrench(day)}</h2>
                <div className="schedule-meals">
                    <div className="lunch">
                        <div className="category">Déjeuner</div>
                        {typeof lunch === 'undefined' && lunches.length !== 0 && unscheduledLunches.length === 0 &&

                            <div className="schedule-nomeal">
                                <p>Toutes les recettes de ce menu ont été planifiées.</p>
                                {/* <Button type="primary">Ajouter</Button> */}
                            </div>

                        }
                        {typeof lunch === 'undefined' && lunches.length !== 0 && unscheduledLunches.length !== 0 &&
                        
                            <div className="meal-selection">
                                <p>Choisis une recette de ton menu pour ce jour-ci</p>
                                <Button type="primary" onClick={props.onMealSchedule(day)}>Planifier</Button>
                            </div>

                        } 
                        {typeof lunch === 'undefined' && lunches.length === 0 &&
                        
                            <div className="schedule-nomeal">
                                <p>Ce menu ne contient aucun déjeuner.</p>
                                <Link to={routes.PLANNING_LUNCHES_CHOICE(props.planning.id)}>+ Ajouter</Link>
                            </div>
                        
                        }
                        {typeof lunch !== 'undefined' &&

                            <ScheduledMeal planningId={id} meal={lunch} onUnpin={props.onMealUnschedule("lunch", lunch.id)} />

                        }
                    </div>
                </div>
            </div> 
        </Col>
    );
}

const ScheduleDinners = (props) => {
    let {
        dinner,
        display,
        day
    } = props.mealDay;

    let {
        dinners,
        id
    } = props.planning;

    let unscheduledDinners = [];
    dinners.forEach((meal) => { if (!tools.isMealScheduled(meal)) unscheduledDinners.push(meal) });

    return(
        <Col {...display}>
            <div className="schedule-day">
                <div className="schedule-meals">
                    <div className="dinner">
                        <div className="category">Dîner</div>
                        {typeof dinner === 'undefined' && dinners.length !== 0 && unscheduledDinners.length === 0 &&

                            <div className="schedule-nomeal">
                                <p>Toutes les recettes de ce menu ont été planifiées.</p>
                                {/* <Button type="primary">Ajouter</Button> */}
                            </div>

                        }
                        {typeof dinner === 'undefined' && dinners.length !== 0 && unscheduledDinners.length !== 0 &&
                        
                            <div className="meal-selection">
                                <p>Choisis une recette de ton menu pour ce jour-ci</p>
                                <Button type="primary" onClick={props.onMealSchedule(day)}>Planifier</Button>
                            </div>

                        } 
                        {typeof dinner === 'undefined' && dinners.length === 0 &&
                        
                            <div className="schedule-nomeal">
                                <p>Ce menu ne contient aucun dîner.</p>
                                <Link to={routes.PLANNING_DINNERS_CHOICE(props.planning.id)}>+ Ajouter des dîners</Link>
                            </div>
                        
                        }
                        {typeof dinner !== 'undefined' &&

                            <ScheduledMeal planningId={id} meal={dinner} onUnpin={props.onMealUnschedule("dinner", dinner.id)} />

                        }
                    </div>
                </div>
            </div> 
        </Col>
    );
}

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDay: props.planning.firstDay,
            isLunchesModalVisible: false,
            isDinnersModalVisible: false,
            scheduleDay: null // the day that will be scheduled a lunch or dinner when a schedule action is launched
        }
    }

    previous = () => {
        if( this.state.currentDay !== this.props.planning.firstDay ) {
            this.setState((prevState) => ({
                currentDay: tools.previousDay(prevState.currentDay)
            }))
        }
    }

    next = () => {
        if( this.state.currentDay !== tools.lastScheduleDay(this.props.planning.firstDay) ) {
            this.setState((prevState) => ({
                currentDay: tools.theDayAfter(prevState.currentDay)
            }))
        }
    }

    hideModal = () => 
        this.setState({ 
            isLunchesModalVisible: false,
            isDinnersModalVisible: false,
            scheduleDay: null,
        })

    handleLunchScheduleRequest = (day) => () => {
        this.setState({ 
            isLunchesModalVisible: true,
            scheduleDay: day
        });
    }

    handleDinnerScheduleRequest = (day) => () => {
        this.setState({ 
            isDinnersModalVisible: true,
            scheduleDay: day
        });
    }

    handlePin = (mealType, mealId) => async () => {
        let { scheduleDay } = this.state;

        this.setState({
            isDinnersModalVisible: false,
            isLunchesModalVisible: false,
        });

        try{
            await this.props.onMealSchedule(mealType, mealId, scheduleDay);
        } catch(err) {
            message.error("Une erreur s'est produite lors de la planification du repas. Merci de réessayer.")
        }

        this.setState({
            isDinnersModalVisible: false,
            isLunchesModalVisible: false,
            scheduleDay: null
        })
        
    }


    render() {
        let schedule = [];

        let {
            lunches,
            dinners,
            firstDay
        } = this.props.planning;

        let { currentDay } = this.state;

        let loopDay = firstDay;
        const isDayMeal = (meal, day) => meal.day === day;

        for (let i = 0; i < 7; i++) {
            let lunch = lunches.find((meal) => isDayMeal(meal, loopDay));
            let dinner = dinners.find((meal) => isDayMeal(meal, loopDay));

            // define how the recipe should be responsively displayed in the calendar
            // based on the day that is currently highlighted and the screen size
            let display = {};
            if( loopDay === currentDay ) {
                display = tools.responsive(24,12,12,8,3,3);
            } else {
                if( currentDay === firstDay ) {
                    if( loopDay === tools.theDayAfter(currentDay) ) {
                        display = tools.responsive(0,12,12,8,3,3);
                    } else if( loopDay === tools.theDayAfter(tools.theDayAfter(currentDay)) ) {
                        display = tools.responsive(0,0,0,8,3,3);
                    } else {
                        display = tools.responsive(0,0,0,0,3,3);
                    }
                } else if( currentDay === tools.lastScheduleDay(firstDay) ) {
                    if( loopDay === tools.theDayBefore(currentDay) ) {
                        display = tools.responsive(0,12,12,8,3,3);
                    } else if( loopDay === tools.theDayBefore(tools.theDayBefore(currentDay))) {
                        display = tools.responsive(0,0,0,8,3,3)
                    } else {
                        display = tools.responsive(0,0,0,0,3,3);
                    }
                } else {
                    if( loopDay === tools.theDayAfter(currentDay) ) {
                        display = tools.responsive(0,12,12,8,3,3);
                    } else if( loopDay === tools.theDayBefore(currentDay) ) {
                        display = tools.responsive(0,0,0,8,3,3);
                    } else {
                        display = tools.responsive(0,0,0,0,3,3);
                    }
                }
            }

            schedule[i] = {
                day: loopDay,
                display: display,
                lunch: lunch,
                dinner: dinner
            }

            loopDay = tools.theDayAfter(loopDay);
        }

        let unscheduledLunches = [];
        lunches.forEach((meal) => { if (!tools.isMealScheduled(meal)) unscheduledLunches.push(meal) });
        
        let unscheduledDinners = [];
        dinners.forEach((meal) => { if (!tools.isMealScheduled(meal)) unscheduledDinners.push(meal) });

        return(
            <div id="schedule">
                <Row className="schedule-nav">
                    <Col {...toolbarResp}>
                        <div className="previous-button">
                            <Button type="primary" shape="circle" icon="left" onClick={this.previous} />
                        </div>
                    </Col>
                    <Col {...toolbarResp}>
                        <div className="next-button">
                            <Button type="primary" shape="circle" icon="right" onClick={this.next} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    {schedule.map((mealDay) => 
                        <ScheduleLunches 
                            key={mealDay.day} 
                            mealDay={mealDay} 
                            planning={this.props.planning} 
                            onMealSchedule={this.handleLunchScheduleRequest}
                            onMealUnschedule={this.props.onMealUnschedule}
                         />
                    )}
                    <Modal
                        title="Planifie un déjeuner"
                        centered
                        visible={this.state.isLunchesModalVisible}
                        footer={null}
                        onCancel={this.hideModal}
                    >
                        {unscheduledLunches.map((meal) => 
                            <SchedulableMeal 
                                key={meal.id} 
                                meal={meal} 
                                onPin={this.handlePin("lunch", meal.id)}
                            />
                        )}
                    </Modal>
                </Row>
                <Row>
                    {schedule.map((mealDay) => 
                        <ScheduleDinners 
                            key={mealDay.day} 
                            mealDay={mealDay} 
                            planning={this.props.planning} 
                            onMealSchedule={this.handleDinnerScheduleRequest}
                            onMealUnschedule={this.props.onMealUnschedule}
                        />
                    )}
                    <Modal
                        title="Choisis une recette pour ton dîner"
                        centered
                        visible={this.state.isDinnersModalVisible}
                        footer={null}
                        onCancel={this.hideModal}
                    >
                        {unscheduledDinners.map((meal) => 
                            <SchedulableMeal 
                                key={meal.id}
                                meal={meal}
                                onPin={this.handlePin("dinner", meal.id)}
                            />
                        )}
                    </Modal>
                </Row>
            </div>
        );
    }
}


export default Schedule