import React from 'react'
import templatePrivateFunnelPage from '../Templates/PrivateFunnelPage'
import { withAuthorization } from '../../../services/sessions'
import MealsParamsForm from '../../Content/Meals/MealsParamsForm'
import { PageHeader } from '../../Content/Layout/Headers'
import * as routes from '../../../constants/routes'
import { Modal, message } from 'antd'
import AddGuestForm from '../../Content/User/AddGuestForm'
import * as users from '../../../api/users'

import './styles.css'

const condition = auth => !!auth

class PlanningLunchesChoiceContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mealsNb: 1,
            guestsChoice: [ this.props.user.uid ],
            isAddGuestModalVisible: false,
            isModalLoading: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGuestAdd = this.handleGuestAdd.bind(this);
        this.handleAddCancel = this.handleAddCancel.bind(this);
        this.showAddGuestModal = this.showAddGuestModal.bind(this);
        this.saveFormRef = this.saveFormRef.bind(this);
    }

    componentDidMount() {
        this.props.setCancel(routes.PLANNING_DETAIL(this.props.match.params.id));

        let page = {
            name: 'Déjeuners / Choix nb'
        }
        this.props.trackPage(this.props.user, page);
    }

    handleChange = changedValues => {
        // console.log("Changed values are : ", changedValues);
        this.setState({ ...changedValues });
    }

    handleSubmit = () => {
        // create a list of guests with more details before pushing to next step
        let mealGuests = {};
        this.state.guestsChoice.map(id => mealGuests[id] = { ...this.props.user.guests[id] });

        // state to push to the match engine
        const stateToPush = {
            planningId: this.props.match.params.id,
            recipeType: "main",
            mealType: "lunch",
            total: this.state.mealsNb,
            guests: mealGuests,
            matched: [],
            discarded: [],
        }
        
        this.props.history.push(routes.RECIPE_MATCHER, stateToPush);
    }

    handleGuestAdd = () => {
        const { form } = this.formRef.props;

        form.validateFields((err, values) => {
            if(!err) {
                this.setState({ isModalLoading: true, }, async () => {
                    // console.log("New guest is : ", values);
                    try {
                        await users.addGuestToUser(this.props.user.uid, { ...values });
                        // console.log("New guest created : ", newGuest);
                        message.success("Invité ajouté !");
                        this.props.userUpdate(() => this.setState({ isAddGuestModalVisible: false, isModalLoading: false, }));
                    } catch(err) {
                        message.error("Une erreur s'est produite lors de l'ajout de l'invité.")
                        this.setState({ isModalLoading: false, });
                    }
                })
            }
        });
    }
    
    handleAddCancel = () => this.setState({ isAddGuestModalVisible: false, })
    showAddGuestModal = () => this.setState({ isAddGuestModalVisible: true, })

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        return(
            <div className="lunches-number-choice">
                <PageHeader label="Tes déjeuners" />
                <MealsParamsForm 
                    planningId={this.props.match.params.id}
                    onChange={this.handleChange}
                    onCancel={this.props.history.goBack} 
                    onSubmit={this.handleSubmit}
                    showAddGuestModal={this.showAddGuestModal}
                    guests={this.props.user.guests}
                    guestsChoice={this.state.guestsChoice}
                    mealsNb={this.state.mealsNb}
                />
                <Modal
                    title="Nouveau convive"
                    visible={this.state.isAddGuestModalVisible}
                    confirmLoading={this.state.isModalLoading}
                    onOk={this.handleGuestAdd}
                    onCancel={this.handleAddCancel}
                    destroyOnClose
                >
                    <AddGuestForm wrappedComponentRef={this.saveFormRef} />
                </Modal>
            </div>
        );
    }
}

const PlanningLunchesChoice = templatePrivateFunnelPage(PlanningLunchesChoiceContent, "Organiser mes repas")

export default withAuthorization(condition)(PlanningLunchesChoice)
