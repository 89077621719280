import React from 'react'
import { Row, Col, Card } from 'antd'

const recipes = [
    {
        key: 1,
        id: "-LmnZ5Zc4O2B3WqNsofR",
        imgUrl: "https://firebasestorage.googleapis.com/v0/b/delizioso-prod.appspot.com/o/images%2Frecipes%2F-LmnZ5Zc4O2B3WqNsofR%2FPa%CC%82te-au-jambon.jpg?alt=media&token=14586498-3e8e-4a81-b927-3b778eec6bdb",
        title: "Tagliatelles jambon et persil plat"
    }, {
        key: 2,
        id: "-LqKqSE7gM95yQZtCbhZ",
        imgUrl: "https://firebasestorage.googleapis.com/v0/b/delizioso-prod.appspot.com/o/images%2Frecipes%2F-LqKqSE7gM95yQZtCbhZ%2FGratin-de-courgettes-lardons-et-chevre.jpg?alt=media&token=619abf13-7f78-40af-9c76-5504d63d1477",
        title: "Gratin de courgettes, lardons et chèvre"
    }, {
        key: 3,
        id: "-LmnaT7_bpeZT0vqO3yx",
        imgUrl: "https://firebasestorage.googleapis.com/v0/b/delizioso-prod.appspot.com/o/images%2Frecipes%2F-LmnaT7_bpeZT0vqO3yx%2FTarte-ricotta-et-epinards.jpg?alt=media&token=4e0a9ee5-f14a-4ca9-86b5-719edbcf0497",
        title: "Tarte ricotta et épinards"
    }
]

const LastRecipes = (props) =>     
    <Row className="recipes-list">
        <Col>
            <h2><span role="img" aria-label="shallow_pan_of_food">🥘  </span>Nos dernières recettes</h2>
        </Col>
        <Col>
            {recipes.map(recipe =>
                <Card 
                    className="recipe" 
                    key={recipe.key}
                    cover={<div className="cover" style={{ backgroundImage: `url(${recipe.imgUrl})`, }}></div>}
                    size="small"
                >
                    <span className="recipe-title">{recipe.title}</span>
                </Card>
            )}
        </Col>
    </Row>

export default LastRecipes