import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { message } from 'antd'
import { getPlanningDetail } from '../../../api/plannings'
import Planning from './Planning'
import * as routes from '../../../constants/routes'
import { MainToolbar } from '../../Content/Layout/Toolbar'
import { createPlanningTracking } from '../../../tools/Segment'
import * as plannings from '../../../api/plannings'


const condition = auth => !!auth

class PlanningDetailContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
        }

        this.handleMealDeletion = this.handleMealDeletion.bind(this)
    }

    handleMealDeletion = (mealType) => async (mealId) => {
        this.props.onLoading();

        try {
            // launch the deletion request to api
            let isMealDeletedOnServer = await plannings.doDeleteMeal({
                planningId: this.state.planning.id,
                mealId: mealId, 
                mealType: mealType
            });

            if( isMealDeletedOnServer ) {
                // let's update the menu in state 'client side' with the new list of meals
                let mealGroup = mealType === "lunch" ? "lunches" : "dinners";
                let updatedMenu = {...this.state.planning};
                let newMealsList = updatedMenu[mealGroup].filter((element) => element.id !== mealId);
                updatedMenu[mealGroup] = newMealsList;

                this.setState({
                    planning: updatedMenu,
                }, () => message.success("Repas supprimé du menu"));

            } else {
                throw new Error("API error when trying to delete the meal");
            }
        } catch(err) {
            console.log("Une erreur s'est produite lors de la suppression du repas : ", err)
            message.error("Une erreur s'est produite. Merci de réessayer.")
        }

        this.props.onLoaded();
    }

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message

        this.props.setBackpage(routes.ORGA_HOME);
        
        this.props.onLoading();
        
        try {
            // retrieve the information about the planning
            let planningFromApi = await getPlanningDetail(this.props.match.params.id);
            
            // update the state with the new object
            this.setState({
                planning: planningFromApi,
            }, 
                () => {
                    this.props.setPageTitle(this.state.planning.label);
                    this.props.setBackpage(routes.ORGA_HOME);
                    
                    // track the page and user
                    let pageTracking = createPlanningTracking("Menu / liste", this.state.planning);

                    this.props.trackPage(this.props.user, pageTracking);
                }
            );
        } catch(err) {
            console.log("Erreur lors de la récupération du menu", err);
            message.error("Une erreur s'est produite lors de la récupération de votre menu. Merci de bien vouloir réessayer.");
        }
        
        this.props.onLoaded();
    }

    render() {
        if( !this.state.planning ) {
            return <div>Chargement de ton menu...</div>
        } else {
            return(
                <Planning 
                    planning={this.state.planning} 
                    onMealDeleted={this.handleMealDeletion}
                    loadMeal={this.props.loadMeal}
                />
            )
        }
    }
}

const PlanningDetail = templatePrivatePage(PlanningDetailContent, "", null, MainToolbar)

export default withAuthorization(condition)(PlanningDetail)
