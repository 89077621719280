import React, { Fragment } from 'react'
import { AuthUserContext } from '../services/sessions/index'

const DriftWidget = (props) => 
    <AuthUserContext.Consumer>
        {({authUser, update}) =>
            !!authUser &&
                <Drift 
                    appId="769mn9pvexif" 
                    userId={authUser.uid}
                    attributes={{ 
                        email: authUser.email,
                        first_name: authUser.firstName,
                        last_name: authUser.name, 
                    }}
                />
        }
    </AuthUserContext.Consumer>


class Drift extends React.Component {
    constructor(props) {
      super(props);
  
      this.addMainScript = this.addMainScript.bind(this);
      this.addIdentityVariables = this.addIdentityVariables.bind(this);
      this.insertScript = this.insertScript.bind(this);
    }
  
    insertScript(scriptText) {
      const script = document.createElement("script");
      script.innerText = scriptText;
      script.async = true;
      document.body.appendChild(script);
    }
    
    addMainScript() {
      const scriptText = `
        "use strict";

        !function() {
          var t = window.driftt = window.drift = window.driftt || [];
          if (!t.init) {
            if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
            t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
            t.factory = function(e) {
              return function() {
                var n = Array.prototype.slice.call(arguments);
                return n.unshift(e), t.push(n), t;
              };
            }, t.methods.forEach(function(e) {
              t[e] = t.factory(e);
            }), t.load = function(t) {
              var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
              o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
              var i = document.getElementsByTagName("script")[0];
              i.parentNode.insertBefore(o, i);
            };
          }
        }();
        drift.SNIPPET_VERSION = '0.3.1';
        drift.load('${this.props.appId}');`;
  
      this.insertScript(scriptText);
    }
  
    // not sure we need to keep this function
    // the drift identify is launched in the withAuthentication component when userAuth status
    // changes 
    addIdentityVariables() {
      if (typeof this.props.userId !== "undefined") {
        let scriptText = `
            var t = window.driftt = window.drift = window.driftt || [];
            drift.identify('${this.props.userId}', ${JSON.stringify(
                this.props.attributes
            )});
        `;
        this.insertScript(scriptText);
      }
    }

    componentDidMount() {
      if (typeof window !== "undefined" && !window.drift) {
        this.addMainScript();
        // this.addIdentityVariables();
      }
    }
  
    render() {
      return <Fragment />;
    }
}

export default DriftWidget