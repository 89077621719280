import React from 'react'
import { Form, Input, Modal } from 'antd'

class MealFormComponent extends React.Component {
    render() {
        let {
            visible,
            onCancel,
            onOk,
            form,
            loading
        } = this.props;

        let { 
            getFieldDecorator
        } = form;

        return(
            <Modal
                maskClosable={false}
                title="Ajout / modification d'un repas"
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
                confirmLoading={loading}
            >
                <Form>
                    <Form.Item label="Entrée">
                    {getFieldDecorator('formStarterId')(
                        <Input placeholder="Saisir l'id de votre entrée" />
                    )}
                    </Form.Item>
                    <Form.Item label="Plat de résistance">
                    {getFieldDecorator('formMainId', {
                        rules: [{ required: true, message: "Merci de saisir un plat de résistance" }]
                    })(
                        <Input placeholder="Saisir l'id de votre plat" />
                    )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const MealForm = Form.create({
    name: "meal_form",
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            formStarterId: Form.createFormField({
                ...props.starterId,
                value: props.starterId.value
            }),
            formMainId: Form.createFormField({
                ...props.mainId,
                value: props.mainId.value
            }),
        }
    }
})(MealFormComponent)

export default MealForm