import React from 'react'
import { Layout, Spin } from 'antd'
import { AuthUserContext } from '../../../../services/sessions'
import { Redirect, withRouter } from 'react-router-dom'
import * as routes from '../../../../constants/routes'

const { Content } = Layout

const templatePublicPage = (PageContent) => {
    class templatePublicPageComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isPageLoading: false,
            }
        
            this.pageLoading = this.pageLoading.bind(this);
            this.pageLoaded = this.pageLoaded.bind(this);
        }

        pageLoading = () => this.setState({ isPageLoading: true, })
        pageLoaded = () => this.setState({ isPageLoading: false, })

        render() {
            return(
                <Spin spinning={this.state.isPageLoading}>
                    <AuthUserContext.Consumer>
                        {({authUser, update}) => {
                            if( typeof authUser === 'undefined' || !authUser ) {
                                return(
                                    <Layout className="app-main public-page">
                                        <Content className="page-content">
                                            <PageContent {...this.props} onLoaded={this.pageLoaded} onLoading={this.pageLoading} />
                                        </Content>
                                    </Layout>
                                );
                            } else if( authUser.ux.ONBOARDING_DONE ) {
                                return(
                                    <Redirect to={routes.HOME} />
                                );
                            } else {
                                return(
                                    <Redirect to={routes.ONBOARDING_STEP1} />
                                );
                            }
                        }
                    }
                    </AuthUserContext.Consumer>
                </Spin>
            );
        }
    }

    return withRouter(templatePublicPageComponent);
}

export default templatePublicPage