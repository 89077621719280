import React from 'react'
import { Form, Input, Radio } from 'antd'

class AddGuestFormComponent extends React.Component {
    render() {
        const { getFieldDecorator } = this.props.form

        return(
            <Form>
                <Form.Item>
                    {getFieldDecorator('firstName', {
                        rules: [ {required: true, message: 'Merci de renseigner ce champ', }]
                    })(
                        <Input placeholder="Prénom" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [ {required: true, message: 'Merci de renseigner ce champ', }]
                    })(
                        <Input placeholder="Nom" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('type', {
                        initialValue: 'FAMILY',
                        rules: [ {required: true, message: 'Merci de choisir une valeur', }]
                    })(
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="FAMILY">Famille</Radio.Button>
                            <Radio.Button value="EXT">Invité</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const AddGuestForm = Form.create('add_guest_form')(AddGuestFormComponent)

export default AddGuestForm