import React from 'react'
import { Table, Checkbox } from 'antd'
import LinkButton from '../../Content/Buttons/LinkButton'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'

const UsersList = (props) => {
    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            render: (text, record) => <Link to={routes.USER_DETAIL(record.uid)}>{record.firstName + ' ' + record.name}</Link>
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }, {
            title: 'Admin',
            dataIndex: 'admin_role',
            render: (text, record) => <Checkbox defaultChecked={typeof record.roles !== 'undefined' && record.roles.ADMIN} disabled />
        }, {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => 
                <LinkButton 
                    to={routes.USER_DETAIL(record.uid)}
                    type="primary"
                    shape="circle"
                    icon="edit"
                />
        }
    ];

    return(
        <Table 
            size="small"
            rowKey={(record) => record.uid}
            dataSource={props.users} 
            columns={columns} 
            pagination={false}
        />
    );
}

export default UsersList