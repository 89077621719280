import apiConfig from './apiConfig'
import { auth } from '../services/firebase'

const apiURI = apiConfig.endpoint + apiConfig.version
const planningsURI = apiURI + '/plannings'

// function that retrieve authenticated user's plannings in db
// it returns an array whose keys are the id's of plannings and 
// each plannings is described with its main data
export const getPlannings = async (userId = null) => {
    try {
        const idToken = await auth.getUserTokenId();
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };

        let requestURI = "";

        if( !userId ) {
            requestURI = planningsURI
        } else {
            requestURI = `${planningsURI}?user=${userId}`;
        }
        
        // send request to the API
        let response = await fetch(requestURI, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        return data.plannings;
    } catch(err) {
        // console.log(err);
        return null;
    }
}

// function that retrieve authenticated user's last planning in db
// it returns an array whose keys are the id's of plannings and 
// return an object that is the planning or an empty object
export const getLastPlanning = async () => {
    try {
        const idToken = await auth.getUserTokenId();
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };

        const lastPlanningURI = planningsURI + '/?last=true';
        // send request to the API
        let response = await fetch(lastPlanningURI, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        // analyze the result in order to return an object
        if( data.plannings.length === 0 ) {
            return {};
        } else if( data.plannings.length === 1 ) {
            return data.plannings[0];
        } else {
            throw new Error("Plus d'un planning renvoyé par l'API. Pas normal !");
        }
    } catch(err) {
        // console.log(err);
        return null;
    }
}

// function that creates a new planning for authenticated user
export const doCreatePlanning = async (label, startDate = null) => {
    try {
        const idToken = await auth.getUserTokenId();
        const userId = auth.getUserId();

        let postData = {
            userId: userId,
            label: label,
        };

        if( !!startDate ) {
            postData.startDate = startDate;
        }
    
        let fetchParams = { 
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
    
        // console.log("params : ", JSON.stringify(postData));

        // send request to the API
        let response = await fetch(planningsURI, fetchParams);
        if( response.status !== 201 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        return data.planningId;

    } catch(err) {
        // console.log(err);
        return null;
    }
}

// function that fetches the detail of one planning based on its id
export const getPlanningDetail = async (planningId, userId = null) => {
    try {
        const idToken = await auth.getUserTokenId();
    
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };
        let query = !userId ? '' : `?user=${userId}`;
        let planningDetailURI = planningsURI + '/' + planningId + query;

        // send request to the API
        // expected success status is 200
        let response = await fetch(planningDetailURI, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        // clean the data in order to be able to use them easily
        // turn lunches, if any, from an object to an array
        let lunches = [];
        if( !!data.lunches ) {
            for( var lunch in data.lunches ) {
                lunches.push({id: lunch, ...data.lunches[lunch]})
            }
        }

        data.lunches = lunches;

        // turn dinners, if any, from an object to an array
        let dinners = [];
        if( !!data.dinners ) {
            for( var dinner in data.dinners ) {
                dinners.push({id: dinner, ...data.dinners[dinner]})
            }
        }

        data.dinners = dinners;

        return data;

    } catch(err) {
        // console.log(err);
        return null;
    }
}

// function that adds meals to a planning
export const doAddMealToPlanning = async (userId, planningId, mealType, main, starter = null, guests = null) => {
    try {
        const idToken = await auth.getUserTokenId();
        // const userId = auth.getUserId();

        let postData = {};
        postData.main = {...main};
        postData.starter = {...starter};
        postData.mealType = mealType;
        postData.userId = userId;
        postData.guests = guests;
    
        let fetchParams = { 
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
        
        console.log("Requête : ", postData);
        let uri = planningsURI + '/' + planningId + '/meals/add'
        // send request to the API
        let response = await fetch(uri, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        // console.log("Data from API after adding meal : ", data);
        return data;

    } catch(err) {
        // console.log(err);
        return null;
    }
}

// function that updates a meal of a planning
export const doUpdateMeal = async ({
    userId = "",
    planningId, // mandatory
    mealId, // mandatory
    mealType, // mandatory
    day, // optional
    main, // optional
    starter // optional
}) => {
    try {
        const idToken = await auth.getUserTokenId();
        // const userId = auth.getUserId();

        let postData = {};
        
        if( typeof day !== 'undefined' ) postData.day = day;
        if( typeof main !== 'undefined' ) postData.main = main;
        if( typeof starter !== 'undefined' ) postData.starter = starter;
        if( userId !== "") postData.userId = userId;
    
        let fetchParams = { 
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };

        let mealGroup = mealType === "dinner" ? "dinners" : "lunches";
        
        let uri = planningsURI + '/' + planningId + '/meals/' + mealGroup + '/' + mealId;
        // send request to the API
        // console.log("URL de la requête : ", uri);
        let response = await fetch(uri, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        return data;

    } catch(err) {
        // console.log(err);
        return null;
    }
}

export const doDeleteMeal = async ({
    planningId, // mandatory
    mealId, // mandatory
    mealType, // mandatory
}) => {
    console.log("Deletion of : ", mealId, " that is a ", mealType," in planning ", planningId);
    
    try {
        const idToken = await auth.getUserTokenId();
        
        let fetchParams = { 
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };

        let mealGroup = mealType === "dinner" ? "dinners" : "lunches";
        
        let uri = planningsURI + '/' + planningId + '/meals/' + mealGroup + '/' + mealId;
        
        // send request to the API
        let response = await fetch(uri, fetchParams);
        if( response.status !== 204 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        return true;
    } catch(err) {
        console.log("Une erreur s'est produite lors de la suppression du repas.");
        return false;
    }
}

export const doRenamePlanning = async (planningId, newLabel) => {
    try {
        const idToken = await auth.getUserTokenId();
        const userId = auth.getUserId();

        let postData = {
            userId: userId,
            label: newLabel
        };
    
        let fetchParams = {
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
    
        let uri = planningsURI + '/' + planningId;
        // console.log("URI : ", uri);
        // send request to the API
        let response = await fetch(uri, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        // console.log("Data : ", data);
        return data;

    } catch(err) {
        // console.log(err);
        return null;
    }
}

export const doChangeFirstDay = async (planningId, newDay) => {
    try {
        const idToken = await auth.getUserTokenId();
        const userId = auth.getUserId();

        let postData = {
            userId: userId,
            startDate: newDay
        };
    
        let fetchParams = {
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
    
        let uri = planningsURI + '/' + planningId;

        // send request to the API
        let response = await fetch(uri, fetchParams);
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        let data = await response.json();
        
        return data;

    } catch(err) {
        // console.log(err);
        return null;
    }
}