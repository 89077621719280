import React from 'react'
import templateOnboardingPage from '../../Templates/OnboardingPage'
import { Row, Col, Icon } from 'antd'
import { withAuthorization } from '../../../../services/sessions'
import { PageHeader } from '../../../Content/Layout/Headers'
import { Step1Cover } from '../../../Content/Layout/PageCover'
import LinkButton from '../../../Content/Buttons/LinkButton'
import * as routes from '../../../../constants/routes'
import { Toolbar } from '../../../Content/Layout/Toolbar'

const condition = auth => !!auth

class PageContent extends React.Component {
    componentDidMount() {
        let page = { name: 'Onboarding / Step 1' };
        this.props.trackPage(this.props.user, page);
    }

    render() {
        return(
            <div className="withToolbar">
                <Step1Cover />
                <PageHeader label="Bienvenue !" />
                <Row className="content">
                    <Col>
                        <p>
                            Je suis Octave et je serai ton assistant dans la cuisine et pour les courses. 
                            Mon objectif est de t'aider à manger plus sain et varié.
                        </p>
                        <p>
                            Comment y parvenir ? En faisant en sorte de reprendre en main son alimentation et notamment son panier 
                            de courses ! En effet nous avons tendance à acheter toujours les mêmes choses et donc à manger les mêmes choses. 
                        </p>
                        <p>
                            Mais nous allons changer cela <span role="img" aria-label="triumph">😤</span>
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

const PageFooter = (props) => 
    <Toolbar className="funnel-toolbar">
        <Col span={12} offset={12}>
            <div className="toolbar-action right-action">
                <LinkButton to={routes.ONBOARDING_STEP2} type="primary">Suite <Icon type="step-forward" /></LinkButton>
            </div>
        </Col>
    </Toolbar>

const OnboardingStep1 = templateOnboardingPage(PageContent, PageFooter)

export default withAuthorization(condition)(OnboardingStep1)