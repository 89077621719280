import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { message } from 'antd'
import { getLastPlanning } from '../../../api/plannings'
import CurrentPlanning from './CurrentPlanning'
import { PageHeader } from '../../Content/Layout/Headers'
import LastRecipes from '../../Content/Recipes/LastRecipes'
import { MainToolbar } from '../../Content/Layout/Toolbar'

const condition = auth => !!auth

class HomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastPlanning: null,
        }
    }

    async componentDidMount() {
        this.props.onLoading();

        try{
            let lastPlanningFromApi = await getLastPlanning();
            
            // set state for the homepage then launch the tracking
            this.setState({
                lastPlanning: lastPlanningFromApi,
            }, () => {
                let page = {
                    name: 'Home'
                }

                this.props.trackPage(this.props.user, page);
            })

        } catch(error) {
            message.error("Une erreur s'est produite lors de la récupération de votre menu");
        }

        this.props.onLoaded();
    }

    render() {
        if( this.state.lastPlanning === null ) {
            return(
                <div>Chargement d'Octave</div>
            )
        } else {
            let welcome = "Bienvenue " + this.props.user.firstName + " !";

            return(
                <div id="home">
                    <PageHeader label={welcome} />
                    <CurrentPlanning lastPlanning={this.state.lastPlanning} />
                    <LastRecipes />        
                    {/* <MainToolbar /> */}
                </div>
            )
        }
    }
}

const Home = templatePrivatePage(HomeContent, "Octave", null, MainToolbar)

export default withAuthorization(condition)(Home)