import React from 'react'
import { Col, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import moment from 'moment'
import 'moment/locale/fr'

class DayEdit extends React.Component {
    handleChange = (date) => {
        if( !!date && this.props.day !== date.valueOf() ) {
            this.props.onChange(date);
        }
    }

    render() {
        let defaultValue = typeof this.props.day !== 'undefined' ? moment(this.props.day) : null;
        
        return (
            <Col>
                <h2>Premier jour du menu</h2>
                <p>
                    Choisis à partir de quel jour de la semaine tu souhaites planifier ton menu. 
                    Cela te permettra de répartir tes recettes sur 7 jours.
                </p>
                <DatePicker 
                    showTime={false} 
                    locale={locale} 
                    defaultValue={defaultValue}
                    format="DD/MM/YYYY" 
                    placeholder="1er jour du menu"
                    onChange={this.handleChange}
                />
            </Col>
        );
    }
}

export default DayEdit