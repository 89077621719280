import React from 'react'
import { Icon } from 'antd'
import './styles.css'

const Title = (props) =>
    <span id="page-title">{props.children}</span>

const HelperText = (props) => 
    <div className="oc-helper-text">
        <Icon className="helper-icon" type="info-circle" theme="outlined" />
        <p>
        {props.children}
        </p>
    </div>

export {
    Title,
    HelperText
}