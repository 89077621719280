import { auth } from './firebase'

export const doSignInWithEmailAndPassword = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)

export const doSignOut = () => 
    auth.signOut()

export const onAuthStateChanged = (fn) => auth.onAuthStateChanged(fn)

// api authentication
// before sending any message we need to check if there is a current user 
// and if this user can connect
export const getUserTokenId = async () => {
    if ( !auth.currentUser ) {
        console.log("Erreur authent user");
        throw new Error('Not authenticated. Make sure you\'re signed in!');
    }
    
    return await auth.currentUser.getIdToken();
}

export const getUserId = () => {
    if ( !auth.currentUser ) {
        console.log("Erreur authent user");
        throw new Error('Not authenticated. Make sure you\'re signed in!');
    }

    return auth.currentUser.uid;
}