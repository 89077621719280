import apiConfig from './apiConfig'
import { auth } from '../services/firebase'

const apiURI = apiConfig.endpoint + apiConfig.version
const usersURI = apiURI + '/users'

// function that fetches the detail of one user based on its id
export const getUserDetail = async (id) => {
    try {
        const idToken = await auth.getUserTokenId();
    
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };
        
        let userDetailURI = usersURI + '/' + id;

        // send request to the API
        // expected success status is 200
        let response = await fetch(userDetailURI, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        return data;

    } catch(err) {
        console.log(err);
    }
}

// function that fetches the list of users
export const getUsers = async (id) => {
    try {
        const idToken = await auth.getUserTokenId();
    
        let fetchParams = { 
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "Authorization": "Bearer " + idToken
            })
        };
        
        // send request to the API
        // expected success status is 200
        let response = await fetch(usersURI, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        return data;

    } catch(err) {
        console.log(err);
    }
}

// function that updates the user's profil in order
// to validate the onboarding completion
export const doUserCompleteOnboarding = async (userId) => {
    try{
        const idToken = await auth.getUserTokenId();

        const patchData = {
            onboardingdone: true,
        };

        let fetchParams = { 
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(patchData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
        
        let uri = usersURI + '/' + userId;

        // send request to the API
        // expected success status is 200
        let response = await fetch(uri, fetchParams);
        // the api should reply a 200 with data as Json
        if( response.status !== 200 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        return data;

    } catch(err) {
        console.log("Error when updating user : ", err);
    }
}

// function that adds a guest to the user's profil 
export const addGuestToUser = async (userId, guestDetail) => {
    try{
        const idToken = await auth.getUserTokenId();

        const postData = { ...guestDetail };

        let fetchParams = { 
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            })
        };
        
        let uri = usersURI + '/' + userId + '/guests/add';

        // send request to the API
        // expected success status is 201
        let response = await fetch(uri, fetchParams);
        // the api should reply a 201 with data as Json
        if( response.status !== 201 ) {
            throw new Error("Erreur durant la requête : " + response.status);
        }

        // turn the json into an object
        let data = await response.json();
        
        return data;

    } catch(err) {
        console.log("Error when updating user : ", err);
    }
}