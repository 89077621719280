import React from 'react'
import { Card } from 'antd'
import { MenuMiniToolbar } from '../Layout/Toolbar'
import { Link } from 'react-router-dom'
import moment from 'moment'

import * as routes from '../../../constants/routes'

// const CardTitle = (props) => 
//     <Link className="unstyled-link" to={routes.PLANNING_DETAIL(props.id)}>{props.title}</Link>

const DinnerCounter = (props) => 
    <div className="metric dinners">
        <span className="value">{props.nbOfDinners}</span>
        <span className="label">{props.nbOfDinners === 0 || props.nbOfDinners === 1 ? "dîner" : "dîners"}</span>
    </div>

const LunchCounter = (props) => 
    <div className="metric lunches">
        <span className="value">{props.nbOfLunches}</span>
        <span className="label">{props.nbOfLunches === 0 || props.nbOfLunches === 1 ? "déjeuner" : "déjeuners"}</span>
    </div>

const FirstDay = (props) => 
    <div className="metric firstday">
        <span className="value">{props.date}</span>
        <span className="label">premier jour</span>
    </div>


const PlanningCard = (props) => {
    // const title = <CardTitle id={props.planning.id} title={props.planning.label} />
    
    let { 
        dinners,
        lunches,
        startDate
    } = props.planning;

    return(
        <Link to={routes.PLANNING_DETAIL(props.planning.id)}>
            <Card 
                className="menu-card"
                headStyle={{ borderRadius: "10px 10px 0 0", backgroundColor: "#008DD5", color: "white", }}
                hoverable={props.clickable}
                size="small"
                title={props.planning.label}
            >
                {/* { !dinners || Object.keys(dinners).length === 0
                    ? <div>Vous n'avez sélectionné aucun dîner.</div>
                    : <div>Vous avez sélectionné {Object.keys(dinners).length} dîner(s)</div>
                }
                { !lunches || Object.keys(lunches).length === 0
                    ? <div>Vous n'avez sélectionné aucun déjeuner.</div>
                    : <div>Vous avez sélectionné {Object.keys(lunches).length} déjeuner(s)</div>
                } */}
                <div className="metrics">
                    <LunchCounter nbOfLunches={ typeof lunches === 'undefined' ? 0 : Object.keys(lunches).length} />
                    <DinnerCounter nbOfDinners={ typeof dinners === 'undefined' ? 0 : Object.keys(dinners).length} />
                    <FirstDay date={typeof startDate === 'undefined' ? '-' : moment(startDate).format("DD/MM/YY")} />
                </div>
            </Card>
        </Link>
    );
}

const PlanningCardWithToolbar = (props) => {
    // const title = <CardTitle id={props.planning.id} title={props.planning.label} />
    
    let { 
        dinners,
        lunches,
        startDate
    } = props.planning;

    const isEmpty = 
        ( typeof lunches === 'undefined' || Object.keys(lunches).length === 0 ) 
        && ( typeof dinners === 'undefined' || Object.keys(dinners).length === 0 );
    
    return(
        <Card 
            className="menu-card"
            headStyle={{ borderRadius: "10px 10px 0 0", backgroundColor: "#008DD5", color: "white", border: "1px solid #008dd5"}}
            bodyStyle={{ borderRadius: "0 0 10px 10px", padding: "18px 0 0 0", }}
            hoverable={props.clickable}
            size="small"
            title={<Link className="card-header-link" to={routes.PLANNING_DETAIL(props.planning.id)}>{props.planning.label}</Link>}
        >
            <Link to={routes.PLANNING_DETAIL(props.planning.id)}>
                <div className="metrics">
                    <LunchCounter nbOfLunches={ typeof lunches === 'undefined' ? 0 : Object.keys(lunches).length} />
                    <DinnerCounter nbOfDinners={ typeof dinners === 'undefined' ? 0 : Object.keys(dinners).length} />
                    <FirstDay date={typeof startDate === 'undefined' ? '-' : moment(startDate).format("DD/MM/YY")} />
                </div>
            </Link>
            <MenuMiniToolbar mainPage={isEmpty} id={props.planning.id} />
        </Card>
    );
}

export {
    PlanningCard,
    PlanningCardWithToolbar
}