import React from 'react'
import { Row, Col } from 'antd'
import { Meals } from '../../Content/Meals/Meals'
import MealCounter from '../../Content/Plannings/MealCounter'
import { MenuToolbar } from '../../Content/Layout/Toolbar'
import { ReactComponent as InspirationImg } from '../../../img/Inspiration.svg'
import { PageHeader } from '../../Content/Layout/Headers'
import * as tools from '../../../tools'

const EmptyPlanning = (props) => 
    <div id="menu" className="planning-empty">
        <Row className="meal-counter--empty" type="flex">
            <Col {...tools.responsive(24,24,24,12,8,6)}>
                <InspirationImg />
            </Col>
            <PageHeader label="En quête d'inspiration ?" />
            <Col {...tools.responsive(24,24,24,12,16,18)}>
                <p>
                    Il est temps d'ajouter de bons repas à ce menu&nbsp;! 
                    Et si tu parcourais mes suggestions de recettes pour tes dîners ou tes déjeuners ?
                </p>
            </Col>
        </Row>
        <Meals planning={props.planning} />
    </div>
    

const ValidatedPlanning = (props) =>
    <div id="menu" className="planning-validated">
        <MenuToolbar list planningId={props.planning.id} />
        <MealCounter planning={props.planning} />
        <Meals planning={props.planning} onMealDeleted={props.onMealDeleted} />
    </div>

const Planning = (props) => {
    // if the planning is empty we display an empty planning
    if( !props.planning  ) {
        return <div>Votre menu n'a pas été trouvé. Merci de bien vouloir réessayer.</div>
    }

    // if no meal have been defined then we display the empty planning state
    if( 
        (!props.planning.dinners || props.planning.dinners.length === 0) 
        && (!props.planning.lunches || props.planning.lunches.length === 0) 
    ) {
        return(
            <EmptyPlanning planning={props.planning} />
        )
    }
    
    // the planning contains meals so we check the status to display it the right way
    if( props.planning.status === "validated" ) {
        return(
            <ValidatedPlanning planning={props.planning} onMealDeleted={props.onMealDeleted} />
        );
    } else {
        return (
            <div id="menu" className="planning-new">
                <MenuToolbar list planningId={props.planning.id} />
                <MealCounter planning={props.planning} />
                <Meals planning={props.planning} onMealDeleted={props.onMealDeleted} loadMeal={props.loadMeal} />
            </div>
        );
    }
}

export default Planning