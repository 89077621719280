import React from 'react'
import { Icon } from 'antd'
import { ReactComponent as OctaveIcon } from './logo.svg'

const SimpleLogo = () =>
    <div className="simple-logo" />

const OctaveLogo = (props) => <Icon component={OctaveIcon} {...props} />

export {
    SimpleLogo,
    OctaveLogo
}