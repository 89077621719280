import React from 'react'
import { List, Icon, Row, Col, Divider } from 'antd'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'


class MealsList extends React.Component {

    render() {
        const renderMeal = meal => 
            <List.Item
                className="meal-list-item"
                key={meal.id}
            >
                {typeof meal.starter !== 'undefined' && meal.starter !== null &&
                
                <Row gutter={8}>
                    <Col span={6}>
                        <Link to={routes.MEAL_DETAIL(meal.id)}>
                            <div className="recipe-pic" style={{ backgroundImage: `url(${meal.starter.imgUrl})`, }} />
                        </Link>
                    </Col>
                    <Col span={18}>
                        <span>{meal.starter.title}</span>
                    </Col>
                </Row>
                
                }
                <Row  gutter={8}>
                    <Col span={6}>
                        <Link to={routes.MEAL_DETAIL(meal.id)}>
                            <div className="recipe-pic" style={{ backgroundImage: `url(${meal.main.imgUrl})`, }} />
                        </Link>
                    </Col>
                    <Col span={18}>
                        <span>{meal.main.title}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Icon type="edit" />
                        <Divider type="vertical" />
                        <Icon type="delete" />
                    </Col>
                </Row>
            </List.Item>

        return(
            <List 
                itemLayout="vertical"
                size="default"
                dataSource={this.props.meals}
                renderItem={renderMeal}
                grid={{
                    column: 1,
                    gutter: 8,
                }}
            />
        );
    }
}

export default MealsList