import React from 'react'
import { Row, Col } from 'antd'
import step1cover from './Onboarding/img/plates.jpg'
import step2cover from './Onboarding/img/orga3.jpg'
import step3cover from './Onboarding/img/beta2.jpg'
import * as tools from '../../../tools'

const respCover = tools.responsive(24,24,24,0,0,0)

const PageCover = (props) => 
    <Row className="page-cover">
        <Col {...respCover}>
            <div className="progress-bar">
                <div className="active" />
                <div className={props.step === 2 || props.step === 3 ? 'active' : 'inactive' } />
                <div className={props.step === 3 ? 'active' : 'inactive' }  />
            </div>
            <div className="cover-img" style={{ backgroundImage: `url(${props.bgSrc}` }} />
        </Col>
    </Row>


const Step1Cover = props => <PageCover bgSrc={step1cover} step={1} />
const Step2Cover = props => <PageCover bgSrc={step2cover} step={2} />
const Step3Cover = props => <PageCover bgSrc={step3cover} step={3} />

export {
    Step1Cover,
    Step2Cover,
    Step3Cover
}