import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import { getPlanningDetail} from '../../../api/plannings'
import { message } from 'antd'
import * as routes from '../../../constants/routes'
import { Redirect } from 'react-router-dom'
import { createPlanningTracking } from '../../../tools/Segment'
import MealCounter from '../../Content/Plannings/MealCounter'
import { MenuToolbar, MainToolbar } from '../../Content/Layout/Toolbar'
import MealsList from '../../Content/Meals/MealsList'

const condition = auth => !!auth

class PlanningDinnersContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
        }
    }

    async componentDidMount() {
        // TODO : handle the case when the planning id is unknown, and the api returned a
        // 404 HTTP message
        this.setState({ isLoading: true, });
        this.props.onLoading();
        // retrieve the information about the planning
        // update the state with the new object    
        try {
            let planningFromApi = await getPlanningDetail(this.props.match.params.id);
            // console.log("Planning récupéré : ",planningFromApi);
            this.setState({
                planning: planningFromApi,
            }, () => {
                if( this.state.planning.dinners.length !== 0 ) {
                    let {
                        label
                    } = this.state.planning;

                    this.props.setPageTitle(label);
                    this.props.setBackpage(routes.ORGA_HOME)
                    
                    let pageTracking = createPlanningTracking('Menu / Mes dîners', this.state.planning);

                    this.props.trackPage(this.props.user, pageTracking);
                }
            });
        } catch(err) {
            console.log("Erreur lors de la récupération du planning", err);
            message.error("Une erreur s'est produite lors de la récupération de votre planning. Merci de bien vouloir réessayer.");
        }
        this.props.onLoaded();
    }

    render() {
        let { planning } = this.state;

        if( !planning ) {
            return(
                <div>Chargement du menu...</div>
            )
        } else {
            let { dinners, id } = planning;
            
            if( !dinners || dinners.length === 0 ) {
                return(
                    <Redirect to={routes.PLANNING_DINNERS_CHOICE(id)} />
                );
            } else {
                return(
                    <div id="menu" className="menu-dinners">
                        <MenuToolbar list planningId={id} />
                        <MealCounter planning={planning} />
                        <MealsList meals={dinners} />
                    </div>
                )
            }
        }
    }
}

const PlanningDinners = templatePrivatePage(PlanningDinnersContent, "", null, MainToolbar)

export default withAuthorization(condition)(PlanningDinners)