import React from 'react'
import templatePrivatePage from '../Templates/PrivatePage'
import { withAuthorization } from '../../../services/sessions'
import MenuCreationForm from './MenuCreationForm'
import * as plannings from '../../../api/plannings'
import * as routes from '../../../constants/routes'
import { Row, Col, message } from 'antd'
import { ReactComponent as Empty } from '../../../img/EmptyMenu.svg'

const condition = auth => !!auth

class MenuCreationContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canCreate: false,
        }

        this.handleCreation = this.handleCreation.bind(this);
        this.saveFormRef = this.saveFormRef.bind(this);
    }

    componentDidMount() {
        let page = {
            name: 'Menu / formulaire création',
        }

        this.props.trackPage(this.props.user, page);
    }

    handleCreation = async (values) => {
        const { history } = this.props;
        
        // console.log("Valeurs : ", values);
        this.props.onLoading();

        try {
            let planningId = await plannings.doCreatePlanning(values.orgalabel, values.startDate);
            
            if( typeof planningId === 'undefined' ) {
                throw new Error();
            }
            
            message.success("Nouveau menu créé avec succès");
            
            this.props.onLoaded();
            
            history.push(routes.PLANNING_DETAIL(planningId));
        } catch(err) {
            this.props.onLoaded();
            message.error("Erreur lors de la création du menu : ", err);
        }
    }

    saveFormRef = formRef => this.formRef = formRef

    render() {        
        return (
            <div id="menu-creation" className="withToolbar">
                <Row type="flex" justify="center">
                    <Col className="illustration">
                        <Empty />
                    </Col>
                    <Col>
                        <p>
                            Tu t'apprêtes à créer un nouveau menu. 
                            Ce menu contiendra ta sélection de recettes (déjeuners et dîners) pour une période de quelques jours.
                        </p>
                    </Col>
                </Row>
                <MenuCreationForm onSubmit={this.handleCreation} wrappedComponentRef={this.saveFormRef} />
            </div>
        );
    }
}

const MenuCreation = templatePrivatePage(MenuCreationContent, "Nouveau menu")

export default withAuthorization(condition)(MenuCreation)