import React from 'react'
import { List } from 'antd'
import { PlanningCard } from './PlanningCard'

const PlanningList = (props) => {
    return(
        <List
            className="planning-list"
            itemLayout="vertical"
            size="small"
            bordered={false}
            dataSource={props.dataSource}
            pagination={false}
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 3,
            }}
            renderItem={item => (
                <List.Item>
                    <PlanningCard planning={item} />
                </List.Item>
            )}
        />
    );
}

export default PlanningList